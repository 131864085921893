//imports-start
/// <reference path="../definitions.d.ts"  />
//imports-end

module Utils {
    export class HashSet {
        Buffer: Dictionary<boolean> = {};

        constructor()
        constructor(item: number | string | null)
        constructor(item: HashSet)
        constructor(items: Array<number | string | null>)
        constructor(item?: Array<number | string | null> | HashSet | number | string | null) {
            if (typeof item == 'number' || typeof item == 'string') {
                this.put(item);
            } else if (item instanceof HashSet) {
                this.putSet(item);
            } else if (item instanceof Array) {
                this.putRange(item);
            }
        }

        public put(item: number | string | null) {
            this.Buffer[item] = true;
        }

        public putSet(item: HashSet) {
            this.putObjectKeys(item.Buffer);
        }

        public putRange(items: Array<number | string | null>) {
            if (!items) {
                return;
            }

            for (let i = 0, len = items.length; i < len; ++i) {
                this.put(items[i]);
            }
        }

        public putObjectKeys(obj: Dictionary<any>) {
            if (obj != null) {
                for (let key in obj) {
                    this.put(key);
                }
            }
        }

        public putObjectKeyFromArray(items: Array<Object>, keyPath: string) {
            if (items != null && keyPath != null) {
                let pathArray = keyPath.split('.');

                for (let i = 0; i < items.length; i++) {
                    let obj = this.getObjectFromPath(items[i], pathArray.slice(0));
                    this.put(obj);
                }
            }
        }

        private getObjectFromPath(obj: Object, keyPath: Array<string>) {
            if (obj == null || keyPath == null || !keyPath.length) {
                return null;
            }

            const subVal = obj[keyPath.shift()];

            if (keyPath.length > 0) {
                if (subVal != null) {
                    return this.getObjectFromPath(subVal, keyPath);
                }
                return null;
            }

            return subVal || null;
        }

        public delete(item: number | string): boolean {
            if (item != null && this.Buffer[item]) {
                delete this.Buffer[item];
                return true;
            }

            return false;
        }

        public clear(): void {
            this.Buffer = {};
        }

        public has(item: number | string): boolean {
            return this.Buffer[item];
        }

        public isEmpty(): boolean {
            return Object.keys(this.Buffer).length === 0;
        }

        public size(): number {
            return this.toArray().length;
        }

        public toArray(): string[] {
            return Object.keys(this.Buffer);
        }

        public toDictionary(): Dictionary<boolean> {
            return $.extend({}, this.Buffer);
        }

        public toIntArray(): number[] {
            const result: number[] = [];

            for (let k in this.Buffer) {
                if (this.Buffer.hasOwnProperty(k)) {
                    result.push(+k);
                }
            }

            return result;
        }
    }

    // Global Test Variable
    if (typeof window.__TEST__ != 'undefined') {
        window.__TEST__.Utils = window.__TEST__.Utils || Utils;
        window.__TEST__.Utils.HashSet = HashSet;
    }
}

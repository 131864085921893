//imports-start
/// <reference path="../definitions.d.ts"  />
//imports-end

module Model {
    export class Settings {
        public Settings: Dictionary<any> = null;
        public BluetoothConfiguration: any = null;
        public ScaleConfiguration: { Devices: Dictionary<IScaleDevice>, AutoReconnect: boolean, SelectedDevice: string } = null;

        public UseGoogleAnalytics: boolean = null;
        public UseAdvancedAnalyticsInformation: boolean = null;
        public readonly PhotoQuality: number = 75;
        public FoldParametergroups: boolean = false;
        public ShowTreeView: boolean = true;
        public AlwaysShowAdvancedRecorditemEditor: boolean = false;
        public ShowCheckpointToolbar: boolean = true;
        public ShowCreatorAndTimeInSidebar: boolean = false;
        public ShowEvaluationInElementInformation: boolean = false;
        public ExpandTreeInSelectionWindow: boolean = true;
        public ShowOnlyAssignedForms: boolean = false;
        public ShowIssueViewerAfterTakingPhotoDuringInspection: boolean = true;
        public ConfirmOverwriteExistingPhotos: boolean = false;
        public RedirectToMainMenuAfterClosingIssueView: boolean = true;
        public ShowAllIssuesInOneReport: boolean = false;
        public ShowMenuItemsAsTiles: boolean = false;
        public ShowOnlyMyIssuesPerDefault: boolean = false;
        public ShowCreationTimestampInIssueReports: boolean = false;
        public ShowIdInIssueReports: boolean = true;
        public ShowResponsibilitiesInIssueReports: boolean = true;
        public ShowLocationInIssueReports: boolean = true;
        public ShowPicturesInIssueReports: boolean = true;
        public EnablePushNotifications: boolean = true;
        public ShowPrioritizedFiles: boolean = true;
        public EnableSyncLog: boolean = false;
        public AutomaticallyOpenNextCheckpoint: Enums.AutomaticallyOpenNextCheckpoint = Enums.AutomaticallyOpenNextCheckpoint.Never;
        public AskBeforeSynchronisation: boolean = true;
        public SyncAtApplicationStart: boolean = false;
        public AutoSync: boolean = true;
        public DeadlineFilterPreset: number = 0;
        public LocationPickerWidth: number = 25;
        public EnableTwoStateCheckbox: boolean = true;
        public AskIfCreateIssueAfterPhotoComment: boolean = true;
        public AskIfCreateIssueAfterTextComment: boolean = false;
        public AutomaticallyChangeRoomAfterRecording: Enums.AutomaticallyChangeRoomAfterRecordingType = Enums.AutomaticallyChangeRoomAfterRecordingType.Always;
        public DisableDynamicTextSize: boolean = true; // Only for Smartdevices
        public ShowTreeCounters: boolean = true;
        public ShowColorsInLocationPicker: boolean = false;
        public EnableViewRefresh: boolean = false;
        public ViewRefreshTimeout: number = 5; // in minutes
        public ShowTabularSubsampleByDefault: boolean = true;
        public TabularSubsampleShowNumber: boolean = true;
        public TabularSubsampleConsistentWidth: boolean = false;
        public SaveReportSorting: { Property: Enums.SortingBy, Order: Enums.SortingOrder } = { Property: Enums.SortingBy.MODIFICATIONTIMESTAMP, Order: Enums.SortingOrder.DESC };
        public AllowAutoSyncOverCell: boolean = false;
        public AutoSyncInterval: number = 15; // in minutes
        public SidebarDefaultVisibility: boolean = true;
        public AskForFollowStateAfterCompletion: boolean = true;
        public ShowNextStateOnUnrecorded: boolean = false;
        public PasteImageAsSignature: boolean = false;
        public ShowElementInfoButtons: boolean = true;
        public DisableProcessingStatusSmiley: boolean = false;

        constructor(settings?: Dictionary<any>) {
            this.prepareSettings(settings);

            if (!Session.IsSmartDeviceApplication) {
                this.DisableDynamicTextSize = true;
            } else if (localStorage) {
                this.loadViewportSetting();
            }
        }

        public loadViewportSetting() {
            this.DisableDynamicTextSize = JSON.parse(localStorage.getItem('_viewport_scale_text_') || 'true');
        }

        private prepareSettings(settings?: Dictionary<any>) {
            if (settings == null || !Utils.HasProperties(settings)) {
                this.ShowMenuItemsAsTiles = true;

                return;
            }

            for (let key in settings) {
                if (settings.hasOwnProperty(key)) {
                    const data = Model.Settings.updateSettingsIfNeeded(key, settings[key]);

                    if (!data.deleted && this.hasOwnProperty(data.key)) {
                        this[data.key] = data.value;
                    }
                }
            }

            if (!settings.hasOwnProperty('ShowMenuItemsAsTiles')) {
                this.ShowMenuItemsAsTiles = true;
            }

            if (!settings.hasOwnProperty('TabularSubsampleShowNumber')) {
                this.TabularSubsampleShowNumber = true;
            }
        }

        private static updateSettingsIfNeeded(key: string, value: boolean | number | string): { key: string, value: any, deleted: boolean } {
            let deleted: boolean = false;

            switch (key) {
                case 'ShowPromptToAutomaticallyChangeRoomAfterRecording':
                    key = 'AutomaticallyChangeRoomAfterRecording';

                    if (value === true) {
                        value = Enums.AutomaticallyChangeRoomAfterRecordingType.Ask
                    } else {
                        value = Enums.AutomaticallyChangeRoomAfterRecordingType.Always
                    }
                    break;
            }

            return { key, value, deleted };
        }

        /**
         * Setzt den neuen Wert für die App Einstellung
         * @param {String} key Schlüsselbezeichnung der Einstellung
         * @param {String} value Neuer Wert für die Einstellung
         * @returns {boolean} Gibt an, ob es eine Veränderung der Einstellung gab
         */
        public Set(key: string, value: any): boolean {
            const hasChanges = JSON.stringify(this[key]) !== JSON.stringify(value);

            this[key] = value;

            return hasChanges;
        }
    }
}

//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../utils/utils.change-image-order.ts"  />
/// <reference path="./utils.file-editor.ts"  />
/// <reference path="./utils.scan.ts"  />
/// <reference path="./info-window/utils.form-info-window.ts"  />
/// <reference path="./info-window/utils.scheduling-info-window.ts"  />
/// <reference path="../enums.ts"  />
//imports-end

module Utils.IssueViewer {
    interface IPropertyRights {
        CustomID: boolean;
        Title: boolean;
        Description: boolean;
        Location: boolean;
        State: boolean;
        Priority: boolean;
        Classifications: boolean;
        Keywords: boolean;
        Deadline: boolean;
        Reminding: boolean;
        Responsibilities: boolean;
        Contacts: boolean;
        ContactGroups: boolean;
        Files: boolean;
        Activate: boolean;
        ConvertToDisturbance: boolean;
        ConvertToTask: boolean;
        Deactivate: boolean;
        EstimatedEffort: boolean;
        Comments: boolean
    }

    interface IAdditionalParams {
        AssignedMeasureOID?: string;
        IsTemporaryIssue?: boolean;
        AssignedFormOID?: string;
        AssignedElementOID?: string;
        ParentIssue?: Model.Issues.Issue;
        RecorditemOID?: string;
        AssignedSchedulingOID?: string;
        ImagePath?: string;
        TemporaryFiles?: any[];
        VoiceMail?: any;
        RootElement?: Model.Scheduling.SchedulingMetadataElement;
        InheritResponsibilities?: boolean;
        Properties?: Array<string>;
        InheritResponsibilitiesFromOU?: boolean;
        Deadline?: Date;
    }

    let isModified: boolean;
    let currentIssue;
    let newIssueRevision;
    let onSave: Function;
    let _onCommentSaved, _onCommentDeleted: Function;
    let additionalParams: IAdditionalParams;
    let assignedForm: Model.Elements.Element;
    let userRoles: string[];
    let temporaryIssueFiles = {};
    let restoreFragment: boolean;
    let isNewIssue, isNewFormIssue, isNewSchedulingIssue, isNewCorrectiveAction;
    let propertyRights: IPropertyRights;
    let quickSelectFollowerStateButtons: Model.ButtonGroup;

    let _donotRedirectAfterSaving: boolean;
    let _isReadonly: boolean;
    let _closeDeferred: Deferred;

    let $docBody = $('body');
    let $viewerOverlay;
    let $win, $content, $fileScroller, $files, $footer;
    let $btnOpenChat, $quickSelectState, $btnAbort, $btnSave;
    let $btnShowFormInformation, $btnShowSchedulingInformation;

    enum IssueImageModificationType {
        CREATED = 0,
        MODIFIED = 1
    }

    function destroy() {
        isModified = false;
        isNewIssue = false;
        isNewFormIssue = false;
        isNewSchedulingIssue = false;
        isNewCorrectiveAction = true;

        _isReadonly = false;

        if ($win) {
            $win.remove();
        }

        if ($viewerOverlay) {
            Utils.Overlay.DestroyWithTimeout($viewerOverlay);
            $viewerOverlay = null;
        }

        if (quickSelectFollowerStateButtons != null) {
            quickSelectFollowerStateButtons.Destroy();
            quickSelectFollowerStateButtons = null;
        }

        assignedForm = null;
        currentIssue = null;
        userRoles = null;
        onSave = null;
        temporaryIssueFiles = {};
        additionalParams = null;
        propertyRights = null;

        $content = null;
        $fileScroller = null;
        $footer = null;
        $btnOpenChat = null;
        $quickSelectState = null;
        $btnAbort = null;
        $btnSave = null;
        $win = null;

        _onCommentSaved = null;
        _onCommentDeleted = null;

        $docBody.off('removeClass.triggerModalOpen').attr('style', '');
        ChangeImageOrder.UnBind();

        if (!Session.IsSmartDeviceApplication) {
            document.removeEventListener('paste', onPasteFiles);
        }

        if (!Utils.RecorditemEditor.IsVisible()) {
            $docBody.removeClass('modal-open');
        }

        if (restoreFragment) {
            Utils.Router.RestoreFragment();
            restoreFragment = false;
        }


        // Wird ggf. für das closed deferred benötigt (siehe Kommentar dazu)
        const tmpNewIssueRevision = newIssueRevision;
        newIssueRevision = null;

        if (_closeDeferred) {
            // Das closed deferred in eine temporäre Variable kopieren,
            // da zwischen resolve und dem zurücksetzen auf null bereits eine andere Stelle diese erneut setzen kann
            const tmpClosedDeferred = _closeDeferred;
            _closeDeferred = null;
            tmpClosedDeferred.resolve(tmpNewIssueRevision);
        }
    }

    function onDisplayImage(filename: string): void {
        let isSeen = false;

        for (let fCnt = 0, fLen = (newIssueRevision.Files || []).length; fCnt < fLen; fCnt++) {
            const file = newIssueRevision.Files[fCnt];

            if (file.Filename === filename && file.IsSeen) {
                isSeen = true;
                break;
            }
        }

        if (!isSeen) {
            $content.find(`li[data-filename="${filename}"] input`).click();
        }
    }

    function onIssueTitleInput() {
        if (newIssueRevision == null) {
            return;
        }

        newIssueRevision.Title = $.trim($(this).val());

        newIssueRevision.AdditionalData = $.extend(true, newIssueRevision.AdditionalData || {}, { IsIssueTitleOverwritten: true });

        setModificationState();
    }

    function onCustomIDInput() {
        let value = $.trim($(this).val());

        newIssueRevision.CustomID = value;

        setModificationState();
    }

    function onIssueDescriptionInput() {
        let value = $.trim($(this).val());

        newIssueRevision.Description = value;

        setModificationState();
    }

    function onBtnStateClick(evt: Event) {
        let $button = $(this);

        evt.stopPropagation();

        if (newIssueRevision.Type === Enums.IssueType.Note) {
            showStateSelectionForNote($button);
        } else {
            showStateSelection($button);
        }
    }

    function showStateSelectionForNote($btn) {
        let onOKClick;
        let listItems, selectedProperties;

        listItems = [];

        if (propertyRights.Activate) {
            listItems.push({
                Title: i18next.t('IssueViewer.Footer.Active'),
                PropertyValue: false
            });
        }

        if (propertyRights.Deactivate) {
            listItems.push({
                Title: i18next.t('IssueViewer.Footer.Archived'),
                PropertyValue: true
            });
        }

        onOKClick = function($selectedItem) {
            newIssueRevision.IsArchived = /true/.test($selectedItem.data('propertyvalue'));
            $btn.text($selectedItem.text());

            setModificationState();
        };

        selectedProperties = [newIssueRevision.IsArchived];

        Utils.CustomDataPicker.Show(listItems, {
            Title: i18next.t('IssueViewer.Content.State'),
            Width: $btn.outerWidth(),
            MaxWindowHeight: $win.height() - 20,
            UseSystemProperties: newIssueRevision.Type !== Enums.IssueType.Note,
            ShowPropertyColors: newIssueRevision.Type !== Enums.IssueType.Note,
            Callback: onOKClick,
            SelectedProperties: selectedProperties,
            IsMultiSelectionAllowed: false,
            HideResetButton: true
        });
    }

    function showStateSelection($btn) {
        let allowedStates: string[] = [];
        let visibleStates: string[] = [];
        let additionalTexts: Dictionary<string> = {};

        (function traverse(state: Model.Properties.Property) {
            let parent: Model.Properties.Property;

            if (state.IsLockedState) {
                additionalTexts[state.OID] = '&nbsp;<span class="icon-lock"></span>';
            }

            if (!!state.ParentOID &&
                state.OID !== currentIssue.StateOID &&
                DAL.Properties.IsUserAllowedToSetState(userRoles,
                    currentIssue,
                    currentIssue.StateOID,
                    state.OID)) {
                allowedStates.push(state.OID);

                parent = state;

                do {
                    if (visibleStates.indexOf(parent.OID) === -1) {
                        visibleStates.push(parent.OID);
                    } else {
                        break;
                    }
                } while ((parent = parent.Parent));
            }

            (state.Children || []).forEach(traverse);
        })(DAL.Properties.GetRootByType(Enums.PropertyType.Status));

        const setSelectedState = function(state: Model.Properties.Property) {
            newIssueRevision.StateOID = state.OID;
            newIssueRevision.StateRevisionOID = state.RevisionOID;
            newIssueRevision.IsArchived = state.ClosedState;
            newIssueRevision.IsDeleted = state.IsDeletedState;

            $btn.html(Templates.IssueViewer.StateText(newIssueRevision));

            if (state.ResponsibleUser &&
                !Utils.InArray(newIssueRevision.Users, Session.User.OID)) {
                addCurrentUserAsResponsible(newIssueRevision);
                renderResponsibilities();
            }

            setModificationState();
        };

        Utils.ElementPickerPopup.Show({
            Items: DAL.Properties.GetByType(Enums.PropertyType.Status),
            RootItem: DAL.Properties.GetRootByType(Enums.PropertyType.Status),
            SelectedItemIdentifiers: [newIssueRevision.StateOID],
            VisibleObjectTypes: [Enums.PropertyType.Status],
            AdditionalTexts: additionalTexts,
            IsNotALocationTree: true,
            SelectionAllowed: true,
            ShowColor: true,
            ShowQRCodeScannerButton: false,
            ForceExpand: true,
            SearchFieldPlaceholder: i18next.t('SearchfieldPlaceholders.Status'),
            WindowTitle: i18next.t('ElementPicker.SelectStatus'),
            OnConfirmSelection: function(result) {
                if (!result.SelectedOID) {
                    return;
                }

                let state = DAL.Properties.GetByOID(result.SelectedOID);

                if (state.IsDeletedState) {
                    Utils.Message.Show(i18next.t('IssueViewer.SetDeletedState.MessageHeader'), i18next.t('IssueViewer.SetDeletedState.MessageBody'), {
                        Yes: function() {
                            setSelectedState(state);
                        },
                        No: true
                    }, null, 1103);
                } else if (state.ClosedState) {
                    Utils.Message.Show(i18next.t('IssueViewer.SetClosedState.MessageHeader'), i18next.t('IssueViewer.SetClosedState.MessageBody'), {
                        Yes: function() {
                            setSelectedState(state);
                        },
                        No: true
                    }, null, 1103);
                } else if (state.IsLockedState) {
                    Utils.Message.Show(i18next.t('IssueViewer.SetLockedState.MessageHeader'), i18next.t('IssueViewer.SetLockedState.MessageBody'), {
                        Yes: function() {
                            setSelectedState(state);
                        },
                        No: true
                    }, null, 1103);
                } else {
                    setSelectedState(state);
                }
            },
            FnItemIsDisabled: function(state: Model.Properties.Property) {
                return (allowedStates || []).indexOf(state.OID) === -1;
            },
            FnHideItem: function(state: Model.Properties.Property) {
                return (visibleStates || []).indexOf(state.OID) !== -1;
            }
        });
    }

    function addCurrentUserAsResponsible(issue: Model.Issues.IssueType): void {
        if (!issue) {
            return;
        }

        // für API <=14 vorbereiten
        if (!Utils.InArray(issue.Users, Session.User.OID)) {
            issue.Users = issue.Users || [];
            issue.Users.push(Session.User.OID);
        }

        // für API >14 vorbereiten
        issue.ResponsibilityAssignments = issue.ResponsibilityAssignments || {};
        issue.ResponsibilityAssignments.Users = issue.ResponsibilityAssignments.Users || {};
        issue.ResponsibilityAssignments.Users[Session.User.OID] = issue.ResponsibilityAssignments.Users[Session.User.OID] || {};
        issue.ResponsibilityAssignments.Users[Session.User.OID].IsResponsible = true;
    }

    function onWindowHeaderClick(evt: Event) {
        if (_isReadonly) {
            return;
        }

        const location = DAL.Elements.GetByOID(currentIssue.AssignedElementOID);
        if (!location) {
            return;
        }

        // is this a supported type? parent not locked?
        if (!Utils.InArray([Enums.IssueType.Task, Enums.IssueType.Note, Enums.IssueType.Disturbance], newIssueRevision.Type) ||
            newIssueRevision.IsLocked ||
            hasParentAndParentIsLocked()) {
            return;
        }

        evt.stopPropagation();

        // get rights from cache, extract method to get dictionary of rights
        const rightsDictionary = Utils.GetActiveUserRights(Session.User.OID, true, currentIssue.AssignedElementOID);

        // modifications allowed to existing issues?
        if (currentIssue.Revision != 0 && !Utils.CanUserModifyIssue(currentIssue, rightsDictionary)) {
            return;
        }

        const listItems = [];

        if (Enums.IssueType.Task == currentIssue.Type) {
            listItems.push({
                Title: i18next.t('Misc.IssueType.Task'),
                PropertyValue: Enums.IssueType.Task
            });
        } else if (Utils.CanUserCreateIssueType(Enums.IssueType.Task, currentIssue.AssignedElementOID, rightsDictionary)) {
            if ((currentIssue.Type === Enums.IssueType.Note &&
                Utils.UserHasIssueRight(currentIssue, Enums.Rights.Issues_ConvertNoteToTask, rightsDictionary)) ||
                currentIssue.Type !== Enums.IssueType.Note) {
                listItems.push({
                    Title: i18next.t('Misc.IssueType.Task'),
                    PropertyValue: Enums.IssueType.Task
                });
            }
        }

        if (Enums.IssueType.Note == currentIssue.Type) {
            listItems.push({
                Title: i18next.t('Misc.IssueType.Note'),
                PropertyValue: Enums.IssueType.Note
            });
        } else if (Utils.CanUserCreateIssueType(Enums.IssueType.Note, currentIssue.AssignedElementOID, rightsDictionary)) {
            listItems.push({
                Title: i18next.t('Misc.IssueType.Note'),
                PropertyValue: Enums.IssueType.Note
            });
        }

        if (Enums.IssueType.Disturbance == currentIssue.Type) {
            listItems.push({
                Title: i18next.t('Misc.IssueType.Disturbance'),
                PropertyValue: Enums.IssueType.Disturbance
            });
        } else if (Utils.CanUserCreateIssueType(Enums.IssueType.Disturbance, currentIssue.AssignedElementOID, rightsDictionary)) {
            if (currentIssue.Type === Enums.IssueType.Task &&
                Utils.UserHasIssueRight(currentIssue, Enums.Rights.Issues_ConvertTaskToDisturbance, rightsDictionary)) {
                listItems.push({
                    Title: i18next.t('Misc.IssueType.Disturbance'),
                    PropertyValue: Enums.IssueType.Disturbance
                });
            } else if (currentIssue.Type === Enums.IssueType.Note &&
                Utils.UserHasIssueRight(currentIssue, Enums.Rights.Issues_ConvertNoteToTask, rightsDictionary) &&
                Utils.UserHasIssueRight(currentIssue, Enums.Rights.Issues_ConvertTaskToDisturbance, rightsDictionary)) {
                listItems.push({
                    Title: i18next.t('Misc.IssueType.Disturbance'),
                    PropertyValue: Enums.IssueType.Disturbance
                });
            }
        }

        if (!listItems.length) {
            return;
        }

        const onOKClick = function($selectedItem) {
            let $btnState = $content.find('button[data-property="state"]');
            let previousType = newIssueRevision.Type;
            let headlineForNewIssue: string;
            let state: Model.Properties.Property;

            function hideDeadline() {
                let $lblDeadline = $win.find('.deadline-label');
                let $btnDeadline = $win.find('.btn[data-property="deadline-timestamp"]');

                $lblDeadline.addClass('hidden');
                $btnDeadline.addClass('hidden');
            }

            function showDeadline() {
                let $lblDeadline = $win.find('.deadline-label');
                let $btnDeadline = $win.find('.btn[data-property="deadline-timestamp"]');

                $lblDeadline.removeClass('hidden');
                $btnDeadline.removeClass('hidden');
            }

            newIssueRevision.Type = parseInt($selectedItem.data('propertyvalue'), 10);

            if (newIssueRevision.ID) {
                $win.find('.type-abbreviation').text(Utils.GetIssueAbbreviation(newIssueRevision.Type));
            } else {
                if (newIssueRevision.Type === Enums.IssueType.Note) {
                    headlineForNewIssue = i18next.t('IssueViewer.NewNote');
                } else if (newIssueRevision.Type === Enums.IssueType.Disturbance) {
                    headlineForNewIssue = i18next.t('IssueViewer.NewDisturbance');
                } else if (newIssueRevision.Type === Enums.IssueType.Inspection) {
                    headlineForNewIssue = i18next.t('IssueViewer.NewInspection');
                } else {
                    headlineForNewIssue = i18next.t('IssueViewer.NewTask');
                }

                if (!!newIssueRevision.CustomID) {
                    headlineForNewIssue += ` (${newIssueRevision.CustomID})`;
                }

                $win.find('h4').html(headlineForNewIssue);
            }

            if (newIssueRevision.Type === Enums.IssueType.Note) {
                if (!!newIssueRevision.StateOID) {
                    state = DAL.Properties.GetByOID(newIssueRevision.StateOID);
                }

                delete newIssueRevision.StateOID;
                delete newIssueRevision.StateRevisionOID;

                newIssueRevision.IsArchived = newIssueRevision.hasOwnProperty('IsArchived') ?
                    newIssueRevision.IsArchived :
                    state ? state.ClosedState : false;

                newIssueRevision.IsDeleted = newIssueRevision.hasOwnProperty('IsDeleted') ?
                    newIssueRevision.IsDeleted :
                    state ? state.IsDeletedState : false;

                $btnState.html(newIssueRevision.IsArchived ? i18next.t('IssueViewer.Content.Archived') : i18next.t('IssueViewer.Content.Active'));

                hideDeadline();
            } else if (previousType === Enums.IssueType.Note) {
                newIssueRevision.StateOID = currentIssue.StateOID;

                if (!newIssueRevision.StateOID) {
                    newIssueRevision.StateOID = currentIssue.IsArchived ?
                        Session.Client.Settings.TicketCompleted :
                        Session.Client.Settings.TicketOpened;
                }

                $btnState.html(Utils.RenderProperty(DAL.Properties.GetByOID(newIssueRevision.StateOID)));

                showDeadline();
            }

            const issue = newIssueRevision;
            const callback = onSave; //Backup before destroy
            const _additionalParams = additionalParams;
            destroy();
            additionalParams = _additionalParams;
            onSave = callback;
            render(issue);

            setModificationState();
        };

        const selectedProperties = [newIssueRevision.Type];

        let windowWidth = $content.find('.btn:first').outerWidth();

        if (!windowWidth) {
            windowWidth = 350;
        }

        if (windowWidth > $win.outerWidth()) {
            windowWidth = $win.outerWidth();
        }

        Utils.CustomDataPicker.Show(listItems, {
            Title: i18next.t('IssueViewer.Content.Type'),
            Width: windowWidth,
            MaxWindowHeight: $win.height() - 20,
            UseSystemProperties: false,
            Callback: onOKClick,
            SelectedProperties: selectedProperties,
            IsMultiSelectionAllowed: false,
            HideResetButton: true
        });
    }

    function onBtnPriorityClick() {
        let $btn = $(this);

        Utils.ElementPickerPopup.Show({
            Items: DAL.Properties.GetByType(Enums.PropertyType.Priority),
            RootItem: DAL.Properties.GetRootByType(Enums.PropertyType.Priority),
            SelectedItemIdentifiers: [newIssueRevision.PriorityOID],
            VisibleObjectTypes: [Enums.PropertyType.Priority],
            IsNotALocationTree: true,
            SelectionAllowed: true,
            ShowColor: true,
            ShowQRCodeScannerButton: false,
            SearchFieldPlaceholder: i18next.t('SearchfieldPlaceholders.Priorities'),
            WindowTitle: i18next.t('ElementPicker.SelectPriority'),
            OnConfirmSelection: function(result) {
                if (!result.SelectedOID) {
                    return;
                }

                let priority = DAL.Properties.GetByOID(result.SelectedOID);

                newIssueRevision.PriorityOID = priority.OID;
                newIssueRevision.PriorityRevisionOID = priority.RevisionOID;

                $btn.html(Utils.RenderProperty(priority));

                setModificationState();
            }
        });
    }

    function onBtnClassificationsClick() {
        let $btn = $(this);

        Utils.ElementPickerPopup.Show({
            Items: DAL.Properties.GetByType(Enums.PropertyType.Classification),
            RootItem: DAL.Properties.GetRootByType(Enums.PropertyType.Classification),
            SelectedItemIdentifiers: newIssueRevision.Classifications,
            VisibleObjectTypes: [Enums.PropertyType.Classification],
            IsNotALocationTree: true,
            MultiSelectionAllowed: true,
            ShowResetButton: true,
            ShowQRCodeScannerButton: false,
            SearchFieldPlaceholder: i18next.t('SearchfieldPlaceholders.Classifications'),
            WindowTitle: i18next.t('ElementPicker.SelectClassifications'),
            /*
            OnItemClick: function($item) {
                $item.toggleClass('selected');
            },
            */
            OnConfirmSelection: function(result) {
                let strSelectedClassifications = [];
                let selectedClassifications = $.map(result.SelectedItems, function(identifier: string) {
                    const property = DAL.Properties.GetByOID(identifier);

                    // Property is maybe deleted
                    if (property) {
                        strSelectedClassifications.push(property.Title);
                    }

                    return identifier;
                });

                if (strSelectedClassifications.length) {
                    strSelectedClassifications.sort(Utils.SortByString);

                    newIssueRevision.Classifications = selectedClassifications;
                } else {
                    strSelectedClassifications.push(i18next.t('IssueViewer.Content.NoClassificationsSelected'));

                    newIssueRevision.Classifications = null;
                }

                $btn.html(strSelectedClassifications.join(', '));

                setModificationState();
            },
            OnResetButtonClick: function() {
                newIssueRevision.Classifications = null;

                $btn.html(i18next.t('IssueViewer.Content.NoClassificationsSelected'));
                setModificationState();
            }
        });
    }

    function onBtnKeywordsClick() {
        let $btn = $(this);

        Utils.ElementPickerPopup.Show({
            Items: DAL.Properties.GetByType(Enums.PropertyType.Keyword),
            RootItem: DAL.Properties.GetRootByType(Enums.PropertyType.Keyword),
            SelectedItemIdentifiers: newIssueRevision.Keywords,
            VisibleObjectTypes: [Enums.PropertyType.Keyword],
            IsNotALocationTree: true,
            MultiSelectionAllowed: true,
            ShowQRCodeScannerButton: false,
            ShowResetButton: true,
            SearchFieldPlaceholder: i18next.t('SearchfieldPlaceholders.Keywords'),
            WindowTitle: i18next.t('ElementPicker.SelectKeywords'),
            /*
            OnItemClick: function($item) {
                $item.toggleClass('selected');
            },
            */
            OnConfirmSelection: function(result) {
                let strSelectedKeywords = [];
                let selectedKeywords = $.map(result.SelectedItems, function(identifier) {
                    const property = DAL.Properties.GetByOID(identifier);

                    // Property is maybe deleted
                    if (property) {
                        strSelectedKeywords.push(property.Title);
                    }

                    return identifier;
                });

                if (strSelectedKeywords.length) {
                    strSelectedKeywords.sort(Utils.SortByString);

                    newIssueRevision.Keywords = selectedKeywords;
                } else {
                    strSelectedKeywords.push(i18next.t('IssueViewer.Content.NoKeywordsSelected'));

                    newIssueRevision.Keywords = null;
                }

                $btn.html(strSelectedKeywords.join(', '));

                setModificationState();
            },
            OnResetButtonClick: function() {
                newIssueRevision.Keywords = null;

                $btn.html(i18next.t('IssueViewer.Content.NoKeywordsSelected'));
                setModificationState();
            }
        });
    }

    function onBtnDeadlineTimestampClick(evt: Event) {
        let $this = $(this);

        evt.stopPropagation();

        Utils.DateTimePicker.Show({
            ShowTime: true,
            ShowCalendar: true,
            Clearable: true,
            SelectedDateTime: newIssueRevision.DeadlineTimestamp,
            HeaderText: i18next.t('IssueViewer.Content.Deadline'),
            FnSuccess: function(timestamp) {
                newIssueRevision.DeadlineTimestamp = timestamp;
                $this.html(timestamp ? Utils.DateTime.ToString(timestamp) : i18next.t('IssueViewer.Content.NoDeadlineSelected'));

                setModificationState();
            }
        });
    }

    function onBtnRemindingTimestampClick(evt: Event) {
        let $this = $(this);

        evt.stopPropagation();

        Utils.DateTimePicker.Show({
            ShowTime: true,
            ShowCalendar: true,
            Clearable: true,
            SelectedDateTime: newIssueRevision.RemindingTimestamp,
            HeaderText: i18next.t('IssueViewer.Content.RemindingTimestamp'),
            FnSuccess: function(timestamp) {
                newIssueRevision.RemindingTimestamp = timestamp;
                $this.html(timestamp ? Utils.DateTime.ToString(timestamp) : i18next.t('IssueViewer.Content.NoRemindingTimestampSelected'));

                setModificationState();
            }
        });
    }

    function onBtnAssignedLocationClick(evt: Event) {
        if (_isReadonly || $(this).hasClass('readonly')) {
            return;
        }

        evt.stopPropagation();

        showLocationPicker();
    }

    function onBtnSetResponsibilitiesClick() {
        // INFO: (old) Responsibilities != Contacts/ContactGroups
        const editResponsibilities = Utils.UserHasIssueRight(currentIssue, Enums.Rights.IssueProperties_Responsibilities);
        const editContacts = Utils.IsLicenseAvailable('Contacts', false) && Utils.UserHasIssueRight(currentIssue, Enums.Rights.IssueProperties_Contacts);
        const editContactGroups = Utils.IsLicenseAvailable('Contacts', false) && Utils.UserHasIssueRight(currentIssue, Enums.Rights.IssueProperties_ContactGroups)
            && Session.LastKnownAPIVersion >= 14;

        Utils.ResponsibilitiesPicker.Show({
            AllowUsers: editResponsibilities,
            AllowTeams: editResponsibilities,
            AllowContacts: editContacts,
            AllowContactGroups: editContactGroups,
            FilterIssueResponsibilities: true,
            Issue: currentIssue,
            ShowSystemUsers: false,
            ShowLockedUsers: false,
            ResponsibilityAssignments: newIssueRevision.ResponsibilityAssignments
        }).then((newResponsibilityAssignments: Model.Issues.ResponsibilityAssignments) =>
            updateIssueResponsibilities(newResponsibilityAssignments));
    }

    function onBtnContactGroupsClick(evt: Event) {
        const $this = $(this);

        evt.stopPropagation();
        const onGetSelection = (result) => {
            let strSelectedContactGroups = result ?
                Utils.ConcatManyContactGroupsToString(result.SelectedItems) :
                null;

            if (!strSelectedContactGroups) {
                strSelectedContactGroups = i18next.t('IssueViewer.Content.NoContactGroupsSelected');
            }

            newIssueRevision.ContactGroups = result ?
                (result.SelectedItems || []).length ? result.SelectedItems : null :
                null;

            $this.html(strSelectedContactGroups);

            setModificationState();
        };

        const visibleContactGroups: Array<Model.ContactGroups.ContactGroup> = [];
        const allContactGroups = DAL.ContactGroups.GetAll();
        const cLen = (allContactGroups || []).length;

        for (let cCnt = 0; cCnt < cLen; cCnt++) {
            const contactGroup = allContactGroups[cCnt];

            if (!(contactGroup.Contacts || []).length) {
                continue;
            }

            if ((contactGroup.Locations || []).length) {
                let inHierarchie = false;
                let location = DAL.Elements.GetByOID(newIssueRevision.AssignedElementOID);

                while (Utils.IsSet(location)) {
                    if (Utils.InArray(contactGroup.Locations, location.OID)) {
                        inHierarchie = true;
                        break;
                    }

                    location = location.ParentOID ? DAL.Elements.GetByOID(location.ParentOID) : null;
                }

                if (!inHierarchie) {
                    continue;
                }
            }

            visibleContactGroups.push(contactGroup);
        }

        Utils.ElementPickerPopup.Show({
            NodeHeight: 60,
            Items: visibleContactGroups,
            SelectedItemIdentifiers: newIssueRevision.ContactGroups,
            RenderAsListView: true,
            HideUnmatchedListViewItems: true,
            IsNotALocationTree: true,
            MultiSelectionAllowed: true,
            ShowResetButton: true,
            ItemTitlePattern: '{Title}',
            SearchFields: ['Title'],
            SearchFieldPlaceholder: i18next.t('SearchfieldPlaceholders.ContactGroups'),
            WindowTitle: i18next.t('ElementPicker.SelectContactGroups'),
            OnConfirmSelection: onGetSelection,
            OnResetButtonClick: onGetSelection
        });
    }

    function onBtnEstimatedEffortClick(evt: Event) {
        let $this = $(this);

        evt.stopPropagation();

        const onGetSelection = function(result) {
            let unitTitle = getEstimatedEffortUnitTitle();
            let estimatedEffort = result ?
                result.SelectedOID :
                null;

            if (!estimatedEffort) {
                estimatedEffort = i18next.t('IssueViewer.Content.NoEstimatedEffortSelected');
                unitTitle = '';
            }

            newIssueRevision.EstimatedEffort = result ?
                result.SelectedOID ? parseInt(result.SelectedOID, 10) : null :
                null;

            $this.html(estimatedEffort + unitTitle);

            setModificationState();
        };

        Utils.ElementPickerPopup.Show({
            NodeHeight: 60,
            Items: createEstimatedEffortList(),
            SelectedItemIdentifier: (newIssueRevision.EstimatedEffort || '').toString(),
            RenderAsListView: true,
            HideUnmatchedListViewItems: false,
            IsNotALocationTree: true,
            MultiSelectionAllowed: false,
            ShowResetButton: true,
            KeyProperty: 'Key',
            ItemTitlePattern: '{EstimatedEffort}',
            SearchFields: ['EstimatedEffort'],
            SearchFieldPlaceholder: i18next.t('SearchfieldPlaceholders.EstimatedEffort'),
            WindowTitle: i18next.t('ElementPicker.SelectEstimatedEffort'),
            OnConfirmSelection: onGetSelection,
            OnResetButtonClick: onGetSelection
        });
    }

    function getEstimatedEffortUnitTitle() {
        if (!Session || !Session.Client || !Session.Client.Settings || !Session.Client.Settings.EstimatedEffortSettings) {
            return;
        }

        let estimatedEffortSettings = Session.Client.Settings.EstimatedEffortSettings;

        if (typeof estimatedEffortSettings === 'string') {
            estimatedEffortSettings = JSON.parse(estimatedEffortSettings);
        }

        const unitOID = estimatedEffortSettings.UnitOID;
        const unit = DAL.Properties.GetByOID(unitOID);

        if (!unit || !unit.Title) {
            return '';
        }

        return ' ' + unit.Title;
    }

    function createEstimatedEffortList() {
        if (!Session || !Session.Client || !Session.Client.Settings || !Session.Client.Settings.EstimatedEffortSettings) {
            return;
        }

        const estimatedEffortList = [];
        let range, rangeLength;
        let estimatedEffortSettings = Session.Client.Settings.EstimatedEffortSettings;

        if (typeof estimatedEffortSettings === 'string') {
            estimatedEffortSettings = JSON.parse(estimatedEffortSettings);
        }

        range = estimatedEffortSettings.Range || [];
        rangeLength = range.length;

        if (!rangeLength) {
            return;
        }

        const unit = !!estimatedEffortSettings.UnitOID ?
            DAL.Properties.GetByOID(estimatedEffortSettings.UnitOID) :
            null;

        if (estimatedEffortSettings.CustomItems) {
            for (let i = 0; i < rangeLength; i++) {
                let effort = parseInt(range[i], 10);

                if (isNaN(effort)) {
                    continue;
                }

                estimatedEffortList.push({
                    EstimatedEffort: effort + (unit ? ` ${unit.Title}` : ''),
                    Key: effort.toString()
                });
            }
        } else if (estimatedEffortSettings.ConsecutiveNumbers && rangeLength > 1) {
            for (let i = range[0]; i <= range[1]; i++) {
                estimatedEffortList.push({
                    EstimatedEffort: i + (unit ? ` ${unit.Title}` : ''),
                    Key: i.toString()
                });
            }
        }

        return estimatedEffortList;
    }

    function onAfterSavedToServer(issue): Deferred {
        temporaryIssueFiles = {};
        issue.IsModified = false;

        issue = new Model.Issues.RawIssue(issue);
        let prepareDeferred: Deferred;

        if (issue.Type === Enums.IssueType.Inspection &&
            issue.Revision === 1) {
            prepareDeferred = updateElementInspectionCounter();
        } else {
            prepareDeferred = $.Deferred().resolve();
        }

        return prepareDeferred
            .then(function() {
                if (onSave) {
                    onSave(DAL.Issues.PrepareIssue(issue));
                }

                destroy();
            });
    }

    function onAfterSavedToDevice(issue: Model.Issues.Issue) {
        return Utils.RemoveTemporaryImages()
            .then(function() {
                if (!!issue.PrecedingOID) {
                    currentIssue.FollowerOID = issue.OID;
                    return DAL.Issues.SaveToDatabase(currentIssue.CopyRaw ? currentIssue.CopyRaw() : currentIssue, !!currentIssue.CopyRaw)
                        .then(App.UpdateContentHeaderIssueProcessingIndicator);
                }
            })
            .then(() => {
                if (issue.Type === Enums.IssueType.Inspection &&
                    issue.Revision === 1) {
                    return updateElementInspectionCounter();
                }
            })
            .then(() => {
                if (onSave) {
                    onSave(DAL.Issues.PrepareRawIssue(<any>issue));
                    onSave = null;
                }

                destroy();
            });
    }

    function openFile(filename: string, mimeType: string) {
        const file: any = Utils.Where(currentIssue.Files, 'Filename', '===', filename);
        const title = Utils.IsImage(mimeType) ? getImageViewerTitle() :
            file ? file.Title : null;

        Utils.OpenFile(filename,
            false,
            Utils.InArray(DAL.Files.GetVideoFileExtensions(), Utils.GetFileExtension(filename)),
            title,
            mimeType
        );
    }

    function onFileClick(evt: Event) {
        evt.stopImmediatePropagation();

        const $file = $(this).parents('.file');
        const mimeType = $file.data('mimetype');
        const filename = $file.data('filename') || $file.data('tmpoid');
        const isUnsaved = $file.hasClass('unsaved');

        if (Utils.IsImage(mimeType)) {
            const images = $.map(newIssueRevision.Files, (issueFile) => {
                if (Utils.IsImage(issueFile.MimeType)) {
                    return issueFile;
                }
            });

            const canWriteComments = Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyOnCheckpoints, true, currentIssue.AssignedElementOID);

            Utils.OpenImages(images,
                filename,
                false,
                onDisplayImage,
                propertyRights.Files ? updateImageInformation : null,
                getImageViewerTitle(),
                canWriteComments
            );

            return;
        }

        if (Utils.IsAudio(mimeType)) {
            const alternativeFilename = $file.data('alternativefilename');
            Utils.VoiceRecorder.Play(filename, alternativeFilename);
            return;
        }

        if (!isUnsaved) {
            openFile(filename, mimeType);
        }
    }

    function onEditFileInformationClick(evt: Event) {
        if (evt) {
            evt.stopImmediatePropagation();
        }

        const $this = $(this).parents('.file');
        const filename = $this.data('filename') || $this.data('tmpoid');
        const isReadonly = _isReadonly || !Utils.IsSet(propertyRights) || !propertyRights.Files;

        if (!Utils.IsSet(filename)) {
            return;
        }

        const file = getFile(newIssueRevision, filename) || getTempFile(filename) || {};

        if (Utils.IsSet(file)) {
            Utils.FileEditor.Show({
                Title: file.Title,
                Description: file.Description,
                Readonly: isReadonly,
                OnSave: isReadonly ? null : (title, description) => updateFileInformation(filename, title, description)
            });
        }
    }

    export function RenderFile(editedFile): void {
        const $file = $(`#issue-viewer .files > .file[data-filename="${editedFile.Filename}"]`);

        if ($file) {
            $file.next().remove();
            $file.replaceWith(Templates.IssueViewer.ExistingFile(editedFile));
        }
    }

    function onDeleteFile(evt: Event) {
        if ($(this).attr('disabled') === 'disabled') {
            return;
        }

        const $this = $(this).parents('.file');
        const propertyName = $this.data('tmpoid') ?
            'OID' :
            'Filename';
        const identifier = $this.data('tmpoid') ?
            $this.data('tmpoid') :
            $this.data('filename');

        let idx = -1;

        for (let fCnt = 0, fLen = (newIssueRevision.Files || []).length; fCnt < fLen; fCnt++) {
            if (newIssueRevision.Files[fCnt][propertyName] === identifier) {
                idx = fCnt;
                break;
            }
        }

        if (idx > -1) {
            newIssueRevision.Files.splice(idx, 1);
        }

        if (Utils.IsValidGuid(identifier) &&
            temporaryIssueFiles.hasOwnProperty(identifier)) {
            delete temporaryIssueFiles[identifier];

            const regex = new RegExp(Utils.EscapeRegExPattern(identifier));

            for (let mCnt = 0, mLen = (newIssueRevision.TemporaryFilesMarkup || []).length; mCnt < mLen; mCnt++) {
                if (regex.test(newIssueRevision.TemporaryFilesMarkup[mCnt])) {
                    newIssueRevision.TemporaryFilesMarkup.splice(mCnt, 1);
                    break;
                }

                regex.lastIndex = -1;
            }
        }

        $this.next().remove();
        $this.remove();

        setModificationState();

        evt.stopPropagation();
    }

    function onAfterGotImagePath(imageURI: string) {
        const tmpOID: string = uuid();
        const fileExtension = Utils.GetFileExtension(imageURI);
        const newFilename = uuid() + fileExtension;
        const fileTitle = Utils.GetFileTitle(newFilename, '');
        let mimeType = 'image/jpeg';

        Utils.Spinner.Hide();

        if (fileExtension === '.png') {
            mimeType = 'image/png';
        }

        const $unsaved = $(Templates.IssueViewer.NewFile({
            IsImage: true,
            FileInfos: {
                OID: tmpOID,
                MimeType: mimeType,
                FileURI: imageURI,
                Title: fileTitle,
                IsTemporary: true
            },
            Picture: $('<div class="file-image"></div>').append(Utils.GetImageWithMarks({
                Filename: imageURI,
                FilenameContainsPath: true,
                Width: '100%',
                Height: '100%'
            })).html()
        }));

        $unsaved.find('img').on('load', onAfterImageLoaded);

        $files.append($unsaved);
        $files.append(Templates.Files.SortPlaceholder());

        let filePosition = getMaxFilePosition();

        temporaryIssueFiles[tmpOID] = {
            FileURI: imageURI,
            Filename: newFilename,
            MimeType: mimeType,
            Position: ++filePosition,
            Title: fileTitle,
            IsTemporary: true
        };

        setModificationState();
    }

    function onAfterImagesSelectedFromGallery(imageURIs: string[]) {
        (imageURIs || []).forEach(onAfterGotImagePath);
    }

    function onFileButtonClick(evt: Event) {
        evt.preventDefault();

        if ($(this).attr('disabled') === 'disabled') {
            return;
        }

        Utils.Spinner.Show();

        Utils.RequestCamera().then(onAfterGotImagePath, Utils.Spinner.Hide);
    }

    function onFileButtonPress(evt: Event) {
        evt.preventDefault();

        if ($(this).attr('disabled') === 'disabled') {
            return;
        }

        Utils.GetImagesFromGallery({ MaximumImagesCount: 10 })
            .then((images: string[]) => onAfterImagesSelectedFromGallery(images));
    }

    function onBtnCreateVoiceMailClick(evt: Event) {
        evt.preventDefault();

        if ($(this).attr('disabled') === 'disabled') {
            return;
        }

        Utils.VoiceRecorder.Show(onAfterVoiceMailRecorded);
    }

    function onAfterVoiceMailRecorded(voiceMailMetadata) {
        const tmpOID = uuid();
        const filePath = Utils.GetResourcesPath() + voiceMailMetadata.Filename;

        const $unsaved = $(Templates.IssueViewer.NewFile({
            FileInfos: {
                OID: tmpOID,
                MimeType: voiceMailMetadata.MimeType,
                FileURI: filePath,
                Filename: voiceMailMetadata.Filename,
                Title: Utils.GetFileTitle(voiceMailMetadata.Filename, '')
            }
        }));

        $files.append($unsaved);
        $files.append(Templates.Files.SortPlaceholder());

        temporaryIssueFiles[tmpOID] = {
            FileURI: filePath,
            Filename: voiceMailMetadata.Filename,
            MimeType: voiceMailMetadata.MimeType,
            Title: Utils.GetFileTitle(voiceMailMetadata.Filename, '')
        };

        scrollFilesToTheEnd();
        setModificationState();
    }

    function onTemporaryImageClick(): void {
        const $file = $(this).parents('.file');
        let uri = $file.data('tmp-uri');
        const canWriteComments = Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyOnCheckpoints, true, currentIssue.AssignedElementOID)
        let tmpFileOID;
        let marks;
        let name;
        let title;
        let description;

        if (!uri) {
            uri = $file.find('img').attr('src');
            tmpFileOID = $file.data('tmpoid');
        }

        for (let oid in temporaryIssueFiles) {
            const file = temporaryIssueFiles[oid];

            if (!!file.FileURI && file.FileURI === uri ||
                file.OID && file.OID === tmpFileOID) {
                marks = file.Marks;
                name = file.name;
                title = file.Title;
                description = file.Description;
                break;
            }
        }

        Utils.OpenTemporaryImages([{
            Filename: uri,
            OID: tmpFileOID,
            MimeType: Enums.MimeType.Image,
            Marks: marks,
            name: name,
            Title: title,
            Description: description
        }], uri, updateImageInformation, getImageViewerTitle(), canWriteComments);
    }

    function onReadFileError() {
        Utils.Message.Show(i18next.t('IssueViewer.ReadFileError.MessageHeader'), i18next.t('IssueViewer.ReadFileError.MessageBody'), { Close: true });
    }

    function onAfterFileRead(fileInformation: FileInformation): Deferred {
        if (!fileInformation ||
            !fileInformation.Event ||
            !fileInformation.Event.target ||
            !fileInformation.File ||
            !fileInformation.Identifier) {
            return $.Deferred().resolve().promise();
        }

        const deferred = $.Deferred();
        const src = <string>fileInformation.Event.target.result;
        const mimeType = fileInformation.File.type;

        if (mimeType.contains('image/')) {
            const $unsaved = $(Templates.IssueViewer.NewFile({
                IsImage: true,
                FileInfos: {
                    MimeType: mimeType,
                    OID: fileInformation.Identifier,
                    Title: fileInformation.File.Title
                },
                Picture: '<div class="file-image"><img src /></div>'
            }));

            $files.append($unsaved);

            const canvas = $('<canvas>')[0];
            const context = canvas.getContext('2d');
            const imageObj = new Image();
            imageObj.src = src;

            fileInformation.File.Content = src;

            imageObj.onload = function(this: HTMLImageElement) {
                $(canvas).attr({
                    width: this.width,
                    height: this.height
                });

                context.drawImage(this, 0, 0, this.width, this.height);

                const $image = $content.find(`.files .file[data-tmpoid="${fileInformation.Identifier}"] img`);
                $image
                    .on('load', () => {
                        onAfterImageLoaded();
                        deferred.resolve();
                    })
                    .on('error', () => deferred.reject())
                    .attr('src', canvas.toDataURL());
            };
            imageObj.onerror = () => deferred.reject();
        } else {
            $files.append(getFileMarkupForUnsavedNonImageFile(mimeType, fileInformation.Identifier, fileInformation.File.Title));
            deferred.resolve();
        }

        $files.append(Templates.Files.SortPlaceholder());

        setModificationState();

        Utils.RepositionModalWindow($win);

        return deferred.promise();
    }

    function readFile(file: ExtendedFile): Deferred {
        const tmpOID = uuid();

        file.OID = tmpOID;
        file.Position = getMaxFilePosition() + 1;
        file.Title = Utils.GetFileTitle(file.name, '');
        file.IsTemporary = true;

        temporaryIssueFiles[tmpOID] = file;

        return Utils.ReadFile(file, tmpOID)
            .then(onAfterFileRead, onReadFileError);
    }

    function onFileInput(this: HTMLInputElement): void {
        if (typeof FileReader === 'undefined') {
            return;
        }

        Utils.LoopDeferredActions(this.files, readFile)
            .always(() => $(this).val(''));
    }

    function onPasteFiles(evt: any): void {
        if (typeof FileReader === 'undefined') {
            return;
        }

        const files = Utils.DataTransferListToFileArray((evt.clipboardData || evt.originalEvent.clipboardData).items)
            .filter(file => Utils.IsImage(file.type));

        Utils.LoopDeferredActions(files, readFile);
    }

    function onDropImages(evt: any): void {
        evt.preventDefault();

        let files: Array<File>;

        if (evt.originalEvent.dataTransfer.items) {
            files = Utils.DataTransferListToFileArray(evt.originalEvent.dataTransfer.items);
        } else if (evt.originalEvent.dataTransfer.files) { // IE Unterstüzung
            files = Utils.DataTransferListToFileArray(evt.originalEvent.dataTransfer.files, evt.originalEvent.dataTransfer.types);
        }

        Utils.LoopDeferredActions(files, readFile);
    }

    function onImagesDragOver(evt: Event): void {
        evt.preventDefault();
    }

    function onAfterImageLoaded(): void {
        const $this = $(this);
        const $marks = $this.siblings('div');
        const $file = $this.parents('.file');

        if ($file.length) {
            $file.width(Math.max((parseInt($file.css('min-width'), 10) || 200), $this.width()));
        }

        if ($marks.length) {
            $marks.css({
                top: $this.position().top,
                left: $this.position().left,
                width: $this.width(),
                height: $this.height()
            });
        }

        scrollFilesToTheEnd();
    }

    function onSelectIssueLocation(result: { ElementOID: string, Marks?}): void {
        const location = DAL.Elements.GetByOID(result.ElementOID);

        if (!location) {
            return;
        }

        if (!Utils.CanUserCreateIssueType(currentIssue.Type, location)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.IssueCreation.MessageBody'), {
                    Close: true
                }, null, 1103);

            return;
        }

        newIssueRevision.AssignedElementOID = location.OID;
        newIssueRevision.Location = location;
        newIssueRevision.LocationMarkers = result.Marks;

        const $newHeader = $(Templates.IssueViewer.Location({
            Issue: newIssueRevision,
            Properties: propertyRights
        }));

        $win.find('.content-header').html($newHeader.html());

        setModificationState();
    }

    function onAfterQRCodeScanned(result: { text: string }, picker?: Utils.ElementPickerPopup): void {
        const element = DAL.Elements.GetByQRCode(result.text);

        if (!element) {
            Utils.Spinner.Hide();

            Utils.Message.Show(i18next.t('Navigation.ElementUndefined.MessageHeader'),
                i18next.t('Navigation.ElementUndefined.MessageBody'), {
                    Close: true
                }, null, 1112);

            return;
        }

        onSelectIssueLocation({
            ElementOID: element.OID
        });

        Utils.Spinner.Hide();

        if (picker) {
            picker.Destroy();
        }
    }

    function onBtnShowFormInformationClick(): void {
        const formInfoWindow = new Utils.InfoWindow.FormInfoWindow(
            assignedForm || currentIssue.AssignedFormOID
        );

        formInfoWindow.Show();
    }

    function onBtnShowSchedulingInformationClick(): void {
        const schedulingInfoWindow = new Utils.InfoWindow.SchedulingInfoWindow(currentIssue.AssignedSchedulingOID);
        schedulingInfoWindow.Show();
    }

    function onCommentSaved(comment: Model.Comment) {
        newIssueRevision.Comments = newIssueRevision.Comments || [];

        let commentIdx = Utils.GetIndex(newIssueRevision.Comments, comment.OID, 'OID');
        if (commentIdx !== -1) {
            newIssueRevision.Comments.splice(commentIdx, 1);
        }

        newIssueRevision.Comments.push(comment.GetRawEntity());

        currentIssue.Comments = currentIssue.Comments || [];

        commentIdx = Utils.GetIndex(currentIssue.Comments, comment.OID, 'OID');
        if (commentIdx !== -1) {
            currentIssue.Comments.splice(commentIdx, 1);
        }

        currentIssue.Comments.push(comment.GetRawEntity());

        if (Session.IsSmartDeviceApplication) {
            window.Database.GetSingleByKey(Enums.DatabaseStorage.Issues, currentIssue.OID)
                .then(function(issue: Model.Issues.RawIssue) {
                    if (!issue) {
                        return;
                    }

                    issue.Comments = currentIssue.Comments;

                    DAL.Issues.SaveToDatabase(issue, true);
                });
        }

        $btnOpenChat.text(currentIssue.Comments.length);

        if (_onCommentSaved instanceof Function) {
            _onCommentSaved(comment);
        }
    }

    function onCommentDeleted(comment: Model.Comment): void {
        newIssueRevision.Comments = newIssueRevision.Comments || [];

        let commentIdx = Utils.GetIndex(newIssueRevision.Comments, comment.OID, 'OID');
        if (commentIdx !== -1) {
            newIssueRevision.Comments.splice(commentIdx, 1);
        }

        currentIssue.Comments = currentIssue.Comments || [];

        commentIdx = Utils.GetIndex(currentIssue.Comments, comment.OID, 'OID');
        if (commentIdx !== -1) {
            currentIssue.Comments.splice(commentIdx, 1);
        }

        if (Session.IsSmartDeviceApplication) {
            window.Database.GetSingleByKey(Enums.DatabaseStorage.Issues, currentIssue.OID)
                .then(function(issue: Model.Issues.RawIssue) {
                    if (!issue) {
                        return;
                    }

                    issue.Comments = currentIssue.Comments;

                    DAL.Issues.SaveToDatabase(issue, true);
                });
        }

        $btnOpenChat.text(currentIssue.Comments.length);

        if (_onCommentDeleted instanceof Function) {
            _onCommentDeleted(comment);
        }
    }

    function onBtnOpenChatClick(): void {
        const canWriteComments = Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyOnCheckpoints, true, currentIssue.AssignedElementOID);
        const entityInformation = {
            Type: Enums.CommentEntity.Issue,
            Issue: new Model.Issues.Issue(currentIssue)
        };

        Utils.ChatWindow.Show(
            entityInformation,
            currentIssue.Comments, !canWriteComments,
            isNewIssue,
            onCommentSaved,
            onCommentDeleted);
    }

    function onBtnAbortClick(): void {
        if (Session.IsSmartDeviceApplication && temporaryIssueFiles) {
            for (let identifier in temporaryIssueFiles) {
                const file = temporaryIssueFiles[identifier];

                if (Utils.IsAudio(file.MimeType)) {
                    Utils.DeleteFile(file.Filename);
                }
            }
        }

        newIssueRevision = null;

        destroy();
    }

    function onGroupHeaderClick(): void {
        let $this = $(this);

        $this.toggleClass('collapsed');
        $this.siblings('div').toggleClass('hidden');
    }

    function onQuickStateSelected(evt: Event): void {
        const $this = $(evt.currentTarget);
        const identifier = $this.data('identifier');
        const value = $this.data('value');

        if (newIssueRevision.Type === Enums.IssueType.Note) {
            if (value == null) {
                return;
            }

            quickSetNoteStatus(!value);

            return;
        }

        if (identifier == null) {
            return
        }

        const state = DAL.Properties.GetByOID(identifier);

        if (!state) {
            return;
        }

        // Prüfen ob alles erfasst ist, ansonsten zum 1. nicht erfassten PP scrollen und blinken
        const allRequiredRecorded = !currentIssue.RequiredParameterCount || (currentIssue.CollectedRequiredParameterCount >= currentIssue.RequiredParameterCount);
        const userRoles = Utils.GetUserRoles(currentIssue.AssignedElementOID);
        const mayChangeAllIssueStates = Utils.UserHasRight(Session.User.OID, Enums.Rights.ChangeOrSetIncompleteIssueState, true, currentIssue.AssignedElementOID);

        let mayChangeState = DAL.Properties.IsUserAllowedToChangeState(userRoles, currentIssue.StateOID, allRequiredRecorded, mayChangeAllIssueStates);
        if (mayChangeState) {
            // wenn aktueller Status geändert werden darf, prüfen ob auch der Folgestatus gesezt werden darf
            mayChangeState = DAL.Properties.IsUserAllowedToSetState(userRoles, currentIssue, currentIssue.StateOID, identifier)
        }

        function setState() {
            newIssueRevision.StateOID = state.OID;
            newIssueRevision.StateRevisionOID = state.RevisionOID;

            if (state.ClosedState) {
                newIssueRevision.IsArchived = true;
            }

            if (state.IsDeletedState) {
                newIssueRevision.IsDeleted = true;
            }

            if (state.ResponsibleUser) {
                addCurrentUserAsResponsible(newIssueRevision);
            }

            save();
        }

        if (!mayChangeState) {
            if (allRequiredRecorded || mayChangeAllIssueStates) {
                // Hinweis auf fehlende Berechtigung
                Utils.Message.Show(i18next.t('IssueViewer.MissingEditRights.MessageHeader'),
                    i18next.t('IssueViewer.MissingEditRights.MessageBody', { NewStatusTitle: state.Title }), {
                        OK: true
                    }, null, 1103);
            } else {
                // Hinweis, dass unerfassten PP existieren
                Utils.Message.Show(i18next.t('IssueViewer.IncompleteRecording.MessageHeader'),
                    i18next.t('IssueViewer.IncompleteRecording.MessageBody'), {
                        OK: true
                    }, null, 1103);
            }
            return;
        } else if (state.IsDeletedState) {
            Utils.Message.Show(i18next.t('IssueViewer.SetDeletedState.MessageHeader'), i18next.t('IssueViewer.SetDeletedState.MessageBody'), {
                Yes: setState,
                No: true
            }, null, 1103);

            return;
        } else if (state.ClosedState) {
            Utils.Message.Show(i18next.t('IssueViewer.SetClosedState.MessageHeader'), i18next.t('IssueViewer.SetClosedState.MessageBody'), {
                Yes: setState,
                No: true
            }, null, 1103);
        } else if (state.IsLockedState) {
            Utils.Message.Show(i18next.t('IssueViewer.SetLockedState.MessageHeader'), i18next.t('IssueViewer.SetLockedState.MessageBody'), {
                Yes: setState,
                No: true
            }, null, 1103);

            return;
        } else {
            setState();
        }
    }

    function quickSetNoteStatus(isArchived: boolean): void {
        function setState() {
            newIssueRevision.IsArchived = isArchived;

            $btnSave.click();
        }

        if (!isArchived) {
            setState();
        } else {
            Utils.Message.Show(i18next.t('IssueViewer.SetClosedState.MessageHeader'), i18next.t('IssueViewer.SetClosedState.MessageBody'), {
                Yes: setState,
                No: true
            }, null, 1103);
        }
    }

    function scrollFilesToTheEnd(): void {
        if (!($files instanceof $)) {
            return;
        }

        $files.scrollLeft($files.get(0).scrollWidth);
    }

    function scanIssueLocation(picker?: Utils.ElementPickerPopup): void {
        Utils.Spinner.Show();
        Utils.StartScanner((result: { text: string }) => {
            onAfterQRCodeScanned(result, picker);
        });
    }

    function getImageViewerTitle(): string {
        const id = newIssueRevision.ID || 0;
        const revision = newIssueRevision.Revision || 1;
        const title = !newIssueRevision.ID ? $win.find('.modal-title').text() : newIssueRevision.Title || i18next.t('Misc.Untitled');

        return `${id}.${revision} - ${title}`;
    }

    function setFooterButtonVisibility(): void {
        if (isModified || isNewFormIssue || isNewCorrectiveAction || Utils.HasProperties(temporaryIssueFiles)) {
            $btnSave.parent().removeClass('hidden');
        } else {
            $btnSave.parent().addClass('hidden');
        }

        if ($quickSelectState instanceof $) {
            $quickSelectState.parent().removeClass('hidden');

            if (quickSelectFollowerStateButtons != null) {
                quickSelectFollowerStateButtons.Resize();
            }
        }
    }

    function setModificationState(): void {
        if (!Utils.CanUserModifyIssue(currentIssue)) {
            isModified = false;
        } else {
            isModified = !newIssueRevision.ID ||
                checkIfIsModified(currentIssue, newIssueRevision) ||
                Utils.HasProperties(temporaryIssueFiles);
        }

        setFooterButtonVisibility();
    }

    function getIsLocationDisabled(location: Model.Elements.Element): boolean {
        if (!location) {
            return true;
        }

        return !Utils.CanUserCreateIssueType(currentIssue.Type, location);
    }

    function showLocationPicker(): void {
        Utils.ElementPickerPopup.Show({
            SelectedItemIdentifier: newIssueRevision.AssignedElementOID,
            ShowFloorPlan: true,
            ShowColor: Session.Settings.ShowColorsInLocationPicker,
            CannotCollapseRoot: true,
            FloorPlanID: 'issue-picker-floorplan',
            FloorPlanMarks: newIssueRevision.LocationMarkers,
            EnableMarksOnFloorPlan: true,
            FloorPlanMarksFillColor: newIssueRevision.Type === Enums.IssueType.Note ? '#F5D76E' : newIssueRevision.Type === Enums.IssueType.Disturbance ? '#EF9585' : '#75A1D6',
            FloorPlanMarksStrokeColor: newIssueRevision.Type === Enums.IssueType.Note ? '#000000' : '#FFFFFF',
            ShowQRCodeScannerButton: true,
            ShowNfcScannerButton: true,
            OnConfirmSelection: onSelectIssueLocation,
            OnBtnStartQRCodeScannerClick: (picker: Utils.ElementPickerPopup) => {
                scanIssueLocation(picker);
            },
            FnItemIsDisabled: getIsLocationDisabled
        });
    }

    function checkIfIsModified(firstRevision: Model.Issues.Issue, secondRevision: Model.Issues.Issue): boolean {
        if (!Utils.Equals(firstRevision.Type, secondRevision.Type)) {
            return true;
        }

        if (!Utils.Equals(firstRevision.AssignedElementOID, secondRevision.AssignedElementOID)) {
            return true;
        }

        if (!Utils.Equals(firstRevision.LocationMarkers, secondRevision.LocationMarkers, 'locationmarkers')) {
            return true;
        }

        if (!Utils.Equals(firstRevision.Title, secondRevision.Title, 'string')) {
            return true;
        }

        if (!Utils.Equals(firstRevision.CustomID, secondRevision.CustomID, 'string')) {
            return true;
        }

        if (!Utils.Equals(firstRevision.Description, secondRevision.Description, 'string')) {
            return true;
        }

        if (secondRevision.Type === Enums.IssueType.Note && !Utils.Equals(firstRevision.IsArchived, secondRevision.IsArchived) ||
            !Utils.Equals(firstRevision.StateOID, secondRevision.StateOID)) {
            return true;
        }

        if (!Utils.Equals(firstRevision.PriorityOID, secondRevision.PriorityOID)) {
            return true;
        }

        if (!Utils.Equals(firstRevision.DeadlineTimestamp, secondRevision.DeadlineTimestamp, 'datetime')) {
            return true;
        }

        if (!Utils.Equals(firstRevision.RemindingTimestamp, secondRevision.RemindingTimestamp, 'datetime')) {
            return true;
        }

        if (Utils.CheckResponsibilityAssignmentModifications(
            firstRevision.ResponsibilityAssignments, secondRevision.ResponsibilityAssignments)) {
            return true;
        }

        if (!Utils.Equals(firstRevision.Classifications, secondRevision.Classifications, 'array')) {
            return true;
        }

        if (!Utils.Equals(firstRevision.Keywords, secondRevision.Keywords, 'array')) {
            return true;
        }

        if (!Utils.Equals(firstRevision.Files, secondRevision.Files, 'filearray')) {
            return true;
        }

        if (!Utils.Equals(firstRevision.EstimatedEffort, secondRevision.EstimatedEffort, 'int')) {
            return true;
        }

        return false;
    }

    function renderResponsibilities(): void {
        const responsibilities = DAL.Issues.GetResponsibilities(newIssueRevision);
        const $respList = $win.find('.user-teams .responsibilities');

        if (responsibilities && responsibilities.length) {
            const resp = DAL.Issues.GetResponsibilitiesEx(newIssueRevision.ResponsibilityAssignments);
            const filteredResp: Model.Issues.ResponsibilityAssignments = {};
            filteredResp.Users = (newIssueRevision.ResponsibilityAssignments || {}).Users;
            filteredResp.Teams = (newIssueRevision.ResponsibilityAssignments || {}).Teams;
            filteredResp.Contacts = (newIssueRevision.ResponsibilityAssignments || {}).Contacts;
            filteredResp.ContactGroups = (newIssueRevision.ResponsibilityAssignments || {}).ContactGroups;

            const $responsibilities = Templates.IssueViewer.ResponsibilityList(resp);

            if ($respList.length) {
                $respList.replaceWith($responsibilities);
            } else {
                $content.find('.user-teams button[data-property="responsibilities"]').parents('.item').append($responsibilities);
            }
        } else {
            $respList.remove();
        }
    }

    function updateElementInspectionCounter(): Deferred {
        const element = DAL.Elements.GetByOID(newIssueRevision.AssignedElementOID);

        if (!element) {
            return $.Deferred().resolve();
        }

        const additionalData = element.AdditionalData || {
            VisitCounter: 0
        };

        additionalData.VisitCounter++;

        if (!Session.IsSmartDeviceApplication) {
            return Utils.Http.Put(`elements/${element.OID}/additionaldata`, additionalData)
                .fail(function(_response, _state, _error) {
                    throw new Model.Errors.HttpError(_error, _response);
                });
        } else {
            const syncDescription = new Model.Synchronisation.InspectionCounterEntityDescription(
                newIssueRevision.AssignedElementOID,
                null,
                Enums.SyncEntityType.InspectionCounter,
                additionalData,
                null,
                newIssueRevision.ModificationTimestamp);

            return window.Database.SetInStorage(Enums.DatabaseStorage.SyncEntities, syncDescription)
                .then(function() {
                    element.AdditionalData = additionalData;
                    return window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.Elements, Utils.CloneElement(element));
                })
        }
    }

    function prepareNewIssueRevision(): void {
        const now = new Date();

        if ((!newIssueRevision.OID || +newIssueRevision.ID || +newIssueRevision.Revision) &&
            !(additionalParams || {}).IsTemporaryIssue) {
            newIssueRevision.OID = uuid();
            newIssueRevision.EditorOID = Session.User.OID;
            newIssueRevision.ModificationTimestamp = Utils.DateTime.ToGMTString(now);
            newIssueRevision.Revision += 1;

            if (!newIssueRevision.ID) {
                delete newIssueRevision.ID;
            }

            if (newIssueRevision.Revision > 1) {
                newIssueRevision.PrecedingOID = currentIssue.OID;
            }

            delete newIssueRevision.Revisions;
            delete newIssueRevision.Responsibilities;   // deprecated, to be removed if safe
            delete newIssueRevision.AssignedElementRevisionOID;
        } else {
            newIssueRevision.ModificationTimestamp = Utils.DateTime.ToGMTString(newIssueRevision.ModificationTimestamp);
            newIssueRevision.Revision = 1;
        }

        newIssueRevision.CreationTimestamp = Utils.DateTime.ToGMTString(newIssueRevision.CreationTimestamp);

        if (Utils.DateTime.IsValid(newIssueRevision.DeadlineTimestamp)) {
            newIssueRevision.IsExpired = newIssueRevision.DeadlineTimestamp.getTime() < now.getTime();
            newIssueRevision.DeadlineTimestamp = Utils.DateTime.ToGMTString(newIssueRevision.DeadlineTimestamp);
        }

        if (newIssueRevision.RemindingTimestamp) {
            newIssueRevision.RemindingTimestamp = Utils.DateTime.ToGMTString(newIssueRevision.RemindingTimestamp);
        }

        if (newIssueRevision.TerminationTimestamp) {
            newIssueRevision.TerminationTimestamp = Utils.DateTime.ToGMTString(newIssueRevision.TerminationTimestamp);
        }

        if (!Session.IsSmartDeviceApplication) {
            if (newIssueRevision.ID === 0) {
                delete newIssueRevision.ID;
            }

            if ((newIssueRevision.Recorditems || []).length) {
                delete newIssueRevision.Recorditems;
            }

            // deprecated, to be removed if safe
            if ((newIssueRevision.Responsibilities || []).length) {
                delete newIssueRevision.Responsibilities;
            }

            if ((newIssueRevision.Descendants || []).length) {
                delete newIssueRevision.Descendants;
            }

            if ((newIssueRevision.Ancestors || []).length) {
                delete newIssueRevision.Ancestors;
            }

            if (Utils.HasProperties(temporaryIssueFiles)) {
                newIssueRevision.NewFiles = [];

                for (const oid in temporaryIssueFiles) {
                    const temporaryFile = temporaryIssueFiles[oid];

                    if (temporaryFile.ModificationType === IssueImageModificationType.MODIFIED) {
                        continue;
                    }

                    const fileExtension: string = Utils.GetFileExtension(temporaryFile.name, '');

                    if (!temporaryFile.Filename) {
                        temporaryFile.Filename = uuid() + fileExtension;
                    }

                    newIssueRevision.NewFiles.push({
                        Filename: temporaryFile.Filename,
                        Title: Utils.GetFileTitle(temporaryFile.Title, temporaryFile.name),
                        Description: temporaryFile.Description,
                        OID: oid,
                        Position: temporaryFile.Position,
                        MimeType: temporaryFile.type || temporaryFile.MimeType,
                        Marks: temporaryFile.Marks,
                        IsImage: Utils.IsImage(temporaryFile.type),
                        IsAudio: Utils.IsAudio(temporaryFile.type),
                        IsTemporary: true
                    });
                }

                newIssueRevision.Files = (newIssueRevision.Files || []).concat(newIssueRevision.NewFiles);
            }
        } else {
            if (Utils.HasProperties(temporaryIssueFiles)) {
                newIssueRevision.NewFiles = [];

                for (const oid in temporaryIssueFiles) {
                    const temporaryFile = temporaryIssueFiles[oid];

                    if (newIssueRevision.IsTemporary && (newIssueRevision.Files || []).length) {
                        const idx = Utils.Where(newIssueRevision.Files, 'FileURI', '===', temporaryFile.FileURI, true);

                        if (idx !== -1) {
                            const tmp = newIssueRevision.Files[idx];

                            if (tmp) {
                                tmp.Filename = temporaryFile.Filename;
                            }
                        }
                    }

                    if (temporaryFile.ModificationType === IssueImageModificationType.MODIFIED) {
                        continue;
                    }

                    newIssueRevision.NewFiles.push({
                        Filename: temporaryFile.Filename,
                        Title: Utils.GetFileTitle(temporaryFile.Title, temporaryFile.Filename),
                        Description: temporaryFile.Description,
                        OID: oid,
                        Position: temporaryFile.Position,
                        MimeType: temporaryFile.MimeType,
                        Marks: temporaryFile.Marks,
                        FileURI: temporaryFile.FileURI,
                        IsImage: Utils.IsImage(temporaryFile.MimeType),
                        IsAudio: Utils.IsAudio(temporaryFile.MimeType)
                    });
                }

                newIssueRevision.Files = (newIssueRevision.Files || []).concat(newIssueRevision.NewFiles);
            }
        }

        if ((newIssueRevision.Files || []).length) {
            for (let fCnt = newIssueRevision.Files.length - 1; fCnt >= 0; fCnt--) {
                if (newIssueRevision.Files[fCnt].IsDummy) {
                    newIssueRevision.Files.splice(fCnt, 1);
                }
            }
        }

        (newIssueRevision.Files || []).sort(function(a, b) {
            return a.Position > b.Position ? 1 : -1;
        });
    }

    function save(): Deferred {
        Utils.Spinner.Show();

        prepareNewIssueRevision();

        if ((additionalParams || {}).IsTemporaryIssue && (onSave instanceof Function)) {
            delete newIssueRevision.Location;

            onSave({
                Issue: newIssueRevision,
                AdditionalFiles: temporaryIssueFiles
            });

            onSave = null;

            destroy();
            Utils.Spinner.Hide();

            return $.Deferred().resolve({
                Issue: newIssueRevision,
                AdditionalFiles: temporaryIssueFiles
            }).promise();
        }

        if (newIssueRevision.Revision > 1) {
            newIssueRevision.PreviousRevisionIdentifiers = newIssueRevision.PreviousRevisionIdentifiers || [];

            if (Utils.GetIndex(newIssueRevision.PreviousRevisionIdentifiers, newIssueRevision.PrecedingOID) === -1) {
                newIssueRevision.PreviousRevisionIdentifiers.push(newIssueRevision.PrecedingOID);
            }

            // Kommentar bei Änderungen anfordern
            const issuePropertyChanges = DAL.Properties.GetChangesRequiringComment(currentIssue, newIssueRevision);
            if (issuePropertyChanges && issuePropertyChanges.length) {
                Utils.Spinner.Hide();

                return showChangeCommentWindow()
                    .then(() => {
                        if (!newIssueRevision.Comments) {
                            newIssueRevision.Comments = [];
                        }

                        const now = new Date();
                        newIssueRevision.Comments.push(new Model.Comment(
                            uuid(),
                            now,
                            now,
                            Session.User.OID,
                            Session.User.OID,
                            newIssueRevision.ChangeComment,
                            newIssueRevision.OID,
                            Enums.CommentType.IssueChangeComment
                        ))

                        Utils.Spinner.Show();

                        return saveIssueObject();
                    });
            }
        }

        return saveIssueObject();
    }

    function saveIssueObject(): Deferred {
        const issueObject = new Model.TemporaryIssue(newIssueRevision, temporaryIssueFiles, ParameterList.GetDependingOIDOfIssue(newIssueRevision));

        let onAfterSavedCallback = null;
        if (!_donotRedirectAfterSaving) {
            if (!Session.IsSmartDeviceApplication) {
                onAfterSavedCallback = onAfterSavedToServer;
            } else {
                onAfterSavedCallback = onAfterSavedToDevice;
            }
        } else {
            onAfterSavedCallback = $.noop;
        }

        return issueObject.Save()
            .then((issue: Model.Issues.Issue) => onAfterSavedCallback(issue))
            .always(() => {
                Utils.Spinner.HideWithTimeout();
            })
            .fail((err) => {
                Utils.Message.Show(i18next.t('ParameterList.CreateIssue.ErrorMessageHeader'), i18next.t('ParameterList.CreateIssue.ErrorMessageBody'), { OK: true });
            });
    }

    function getFile(issue: Model.Issues.Issue, identifier: string): Model.Issues.File {
        if (!Utils.IsSet(issue)) {
            return;
        }

        let identifierProperty = 'Filename';

        if (Utils.IsValidGuid(identifier)) {
            identifierProperty = 'OID';
        } else if (identifier.startsWith('file://')) {
            identifierProperty = 'FileURI';
        }

        return getFileByProperty(issue.Files, identifierProperty, identifier);
    }

    function getTempFile(identifier) {
        if (Utils.IsValidGuid(identifier)) {
            return temporaryIssueFiles[identifier];
        } else {
            for (let oid in temporaryIssueFiles) {
                const file = temporaryIssueFiles[oid];

                if (file.Filename === identifier || file.FileURI === identifier) {
                    return file;
                }
            }
        }
    }

    function getFileByProperty(fileArray, property, value): Model.Issues.File {
        if (!(fileArray || []).length) {
            return;
        }

        return Utils.Where(fileArray, property, '===', value);
    }

    function updateImageInformation(fileIdentifier: string, marks: string): void {
        let file;
        let fileFound;
        let pictureConfig;
        let isTempFile = false;

        if ((newIssueRevision.Files || []).length) {
            file = getFile(newIssueRevision, fileIdentifier);

            if (file) {
                if (!newIssueRevision.IsTemporary) {
                    fileFound = true;
                }

                if (file.Marks !== marks) {
                    isModified = true;
                }

                file.Marks = marks;

                setFooterButtonVisibility();
            }
        }

        if ((!fileFound || file.IsNewTemporaryFile) && Utils.HasProperties(temporaryIssueFiles)) {
            pictureConfig = {
                File: {
                    Marks: marks
                },
                Width: '100%',
                Height: '100%'
            };

            if (Utils.IsValidGuid(fileIdentifier)) {
                file = temporaryIssueFiles[fileIdentifier];
                pictureConfig.IsBase64 = true;
                pictureConfig.FileContent = $content.find(`.file[data-tmpoid="${fileIdentifier}"]`).find('img').attr('src');
                pictureConfig.File.OID = fileIdentifier;
                pictureConfig.File.Title = file.Title;
                pictureConfig.File.Description = file.Description;
                file.OID = fileIdentifier;
            } else {
                for (let oid in temporaryIssueFiles) {
                    file = temporaryIssueFiles[oid];

                    if (file.FileURI === fileIdentifier || file.Filename == fileIdentifier) {
                        fileIdentifier = oid;

                        pictureConfig.FilenameContainsPath = true;
                        pictureConfig.File.Filename = file.FileURI || file.Filename;
                        pictureConfig.File.Title = file.Title;
                        pictureConfig.File.Description = file.Description;

                        if (Utils.IsValidGuid(fileIdentifier)) {
                            file.OID = fileIdentifier;
                            pictureConfig.File.OID = fileIdentifier;
                        }

                        break;
                    }

                    file = null;
                }
            }

            if (file) {
                if (file.Marks !== marks) {
                    isModified = true;
                }

                file.Marks = marks;
                isTempFile = true;
            }
        }

        if (!file) {
            return;
        }

        if (isModified || newIssueRevision.IsTemporary) {
            if (!isTempFile && !file.IsNewTemporaryFile) {
                file.MimeType = $content.find(`.file[data-filename="${file.Filename}"]`).data('mimetype');
                file.IsImage = true;


                const $newFile = $(Templates.IssueViewer.ExistingFile(file));
                $content.find(`.file[data-filename="${file.Filename}"]`)
                    .replaceWith($newFile);

                $content.find('img')
                    .on('load', onAfterImageLoaded);
            } else {
                file.MimeType = $content.find(`.file[data-tmpoid="${fileIdentifier}"]`).data('mimetype');

                const $newFile = $(Templates.IssueViewer.NewFile({
                    IsImage: true,
                    FileInfos: file,
                    Picture: $('<div class="file-image"></div>').append(Utils.GetImageWithMarks(pictureConfig)).html()
                }));

                $content.find(`.file[data-tmpoid="${fileIdentifier}"]`)
                    .replaceWith($newFile);

                $newFile.find('img')
                    .on('load', onAfterImageLoaded);
            }

            RepositionMarks();
        }
    }

    function updateFileInformation(fileIdentifier: string, title: string, description: string): void {
        const file = getFile(newIssueRevision, fileIdentifier) || getTempFile(fileIdentifier);

        if (!Utils.IsSet(file)) {
            return;
        }

        Utils.UpdateFileTitleAndDescription(file, title, description);

        let $file;

        if (file.IsTemporary) {
            $file = $content.find(`.file[data-tmpoid="${fileIdentifier}"]`);
        } else {
            $file = $content.find(`.file[data-filename="${file.Filename}"]`);
        }

        if (($file || []).length) {
            file.MimeType = $file.data('mimetype');

            const $fileHeader = $file.find('.file-header');
            $fileHeader.toggleClass('has-description', !!file.Description);
            $fileHeader.find('.file-title').text(file.Title);
        }

        setModificationState();
        setFooterButtonVisibility();
    }

    function createFormResubmissionItems(formOID: string) {
        const formElement = DAL.Elements.GetByOID(formOID);
        const resubmissionItems = [];

        if (!formElement) {
            return resubmissionItems;
        }

        const formResubOID = uuid();

        resubmissionItems.push({
            OID: formResubOID,
            ElementOID: formOID,
            ElementRevisionOID: formElement.RevisionOID
        });

        const emailCpIsEnabled = Utils.IsEMailCpEnabled();

        (formElement.Parametergroups || []).forEach(function(group: Model.Elements.Element) {
            if (!group.Enabled) {
                return;
            }

            const isSubsample = group.Type === Enums.ElementType.FormRow;
            let row = isSubsample ? 1 : null;

            if (isSubsample) {
                group.MinSubsampleCount = group.MinSubsampleCount || 1;
            }

            do {
                const groupResubOID = uuid();

                resubmissionItems.push({
                    OID: groupResubOID,
                    ParentOID: formResubOID,
                    ElementOID: group.OID,
                    ElementRevisionOID: group.RevisionOID,
                    Row: isSubsample ? row : null,
                    IsRecordingLocked: group.IsRecordingLockable && group.IsRecordingLockedByDefault
                });

                (group.Parameters || []).forEach(function(param: Model.Elements.Element) {
                    if (!param.Enabled) {
                        return;
                    }

                    if (param.Type === Enums.ElementType.EMailAddresses &&
                        !emailCpIsEnabled) {
                        return;
                    }

                    resubmissionItems.push({
                        OID: uuid(),
                        ParentOID: groupResubOID,
                        ElementOID: param.OID,
                        ElementRevisionOID: param.RevisionOID,
                        Row: isSubsample ? row : null,
                        IsRecordingLocked: group.IsRecordingLockable && group.IsRecordingLockedByDefault
                    });
                });

                if (isSubsample) {
                    row++;
                }
            } while (isSubsample && row <= group.MinSubsampleCount);
        });

        return resubmissionItems;
    }

    function createMeasureResubmissionItems(measureOID: string) {
        const measure = DAL.Elements.GetByOID(measureOID);
        const resubmissionItems = [];

        if (!measure) {
            return resubmissionItems;
        }

        const emailCpIsEnabled = Utils.IsEMailCpEnabled();
        const locations: Model.Elements.Element[] = [];
        let parentLocation: Model.Elements.Element = measure.Parent;
        let parentResubitemOID: string = null;

        do {
            locations.push(Utils.CloneElement(parentLocation));
        } while ((parentLocation = parentLocation.Parent));

        locations.reverse();

        for (let lCnt = 0, lLen = locations.length; lCnt < lLen; lCnt++) {
            parentLocation = locations[lCnt];

            const resubitemOID: string = uuid();

            resubmissionItems.push({
                OID: resubitemOID,
                ElementOID: parentLocation.OID,
                ElementRevisionOID: parentLocation.RevisionOID,
                ParentOID: !!parentLocation.ParentOID ? parentResubitemOID : null
            });

            parentResubitemOID = resubitemOID;
        }

        const resubitemOID: string = uuid();

        resubmissionItems.push({
            OID: resubitemOID,
            ElementOID: measureOID,
            ElementRevisionOID: measure.RevisionOID,
            ParentOID: parentResubitemOID,
            IsRecordingLocked: measure.IsRecordingLockable && measure.IsRecordingLockedByDefault
        });

        parentResubitemOID = resubitemOID;

        if (!measure.Parameters || !measure.Parameters.length) {
            return resubmissionItems;
        }

        for (const param of measure.Parameters) {
            if (!param.Enabled) {
                continue;
            }

            if (param.Type === Enums.ElementType.EMailAddresses &&
                !emailCpIsEnabled) {
                continue;
            }

            resubmissionItems.push({
                OID: uuid(),
                ParentOID: parentResubitemOID,
                ElementOID: param.OID,
                ElementRevisionOID: param.RevisionOID,
                IsRecordingLocked: measure.IsRecordingLockable && measure.IsRecordingLockedByDefault
            });
        }

        return resubmissionItems;
    }

    function getDeadlineTimestamp(referenceTime: Date, priorityOffset?: number): Date {
        if (additionalParams && additionalParams.Deadline && !isNaN(additionalParams.Deadline.getTime())) {
            return additionalParams.Deadline;
        }

        let deadlineOffset: number = parseInt(Session.Client.Settings.TicketDeadlineOffset, 10);

        if (!isNaN(priorityOffset) && priorityOffset > 0) {
            deadlineOffset = priorityOffset;
        }

        const value = new Date(referenceTime.getTime() + deadlineOffset * 60 * 1000);

        if (Utils.DateTime.IsValid(value)) {
            return value;
        }

        return null;
    }

    function getRemindingTimestamp(referenceTime: Date): Date {
        const value = new Date(referenceTime.getTime() + parseInt(Session.Client.Settings.TicketWarningOffset, 10) * 60 * 1000);

        if (Utils.DateTime.IsValid(value)) {
            return value;
        }

        return null;
    }

    function getAssignedElement(): Model.Elements.Element {
        if (additionalParams &&
            additionalParams.AssignedElementOID &&
            DAL.Elements.Exists(additionalParams.AssignedElementOID)) {
            return DAL.Elements.GetByOID(additionalParams.AssignedElementOID);
        }

        if (Session.CurrentLocation &&
            Session.CurrentLocation.Type === Enums.ElementType.Form) {
            const issueOpenInView = IssueView.GetCurrentIssue();

            if (!issueOpenInView) {
                // Fallback
                return DAL.Elements.Root;
            }

            return DAL.Elements.GetByOID(issueOpenInView.AssignedElementOID);
        }

        return Session.CurrentLocation;
    }

    function getParameterCounters(form) {
        let counters = {
            Parameters: 0,
            RequiredParameters: 0
        };

        if ((form.Parametergroups || []).length) {
            form.Parametergroups.forEach(function(group: Model.Elements.Element) {
                if (Utils.HasProperties(group.Requirements)) {
                    return;
                }

                if ((group.Parameters || []).length) {
                    group.Parameters.forEach(function(param: Model.Elements.Element) {
                        counters.Parameters++;

                        if (Utils.HasProperties(param.Requirements)) {
                            return;
                        }

                        if (param.Required) {
                            counters.RequiredParameters++;
                        }
                    });
                }
            });
        }

        return counters;
    }

    function getDefaultTeams(location: Model.Elements.Element, properties: string[]): string[] | null {
        if (!location) {
            return null;
        }

        if (!(Session.Client.Settings.TakeOverAssignedTeams || (additionalParams || {}).InheritResponsibilitiesFromOU) ||
            !(properties || []).length) {
            return null;
        }

        const defaultTeams = [];

        while (location) {
            if (!(location.AssignedTeams || []).length) {
                location = location.Parent;
                continue;
            }

            location.AssignedTeams.forEach(function(locationTeam) {
                const team = DAL.Teams.GetByOID(locationTeam.OID);

                if (Utils.InArray(defaultTeams, locationTeam.OID) ||
                    !team.ParentOID ||
                    !(locationTeam.Properties || []).length) {
                    return;
                }

                if (locationTeam.Properties.some(function(identifier: string) {
                    return properties.indexOf(identifier) !== -1;
                })) {
                    defaultTeams.push(locationTeam.OID);
                }
            });

            location = location.Parent;
        }

        return defaultTeams;
    }

    function createNewIssue(issueType: Enums.IssueType): Model.Issues.Issue {
        let assignedElement: Model.Elements.Element;

        if (isNewSchedulingIssue && isNewFormIssue) {
            assignedElement = DAL.Elements.GetByOID(additionalParams.AssignedElementOID);
        }

        if (!assignedElement) {
            assignedElement = getAssignedElement();
        }

        const now = new Date();
        const form = additionalParams ? DAL.Elements.GetByOID(additionalParams.AssignedFormOID) : null;
        const scheduling = additionalParams ? DAL.Scheduling.GetByOID(additionalParams.AssignedSchedulingOID) : null;
        let priorityDeadlineOffset: number;

        if (scheduling && scheduling.DeadlineOffsetManuallyExecuted) {
            priorityDeadlineOffset = scheduling.DeadlineOffsetManuallyExecuted;
        } else if (form && form.AdditionalSettings && form.AdditionalSettings.DeadlineOffset) {
            priorityDeadlineOffset = parseInt(form.AdditionalSettings.DeadlineOffset, 10);
        }

        const deadline = getDeadlineTimestamp(now, priorityDeadlineOffset);
        const remindingTimestamp = getRemindingTimestamp(now);

        let properties = [];

        if (form && (form.Properties instanceof Array)) {
            properties = properties.concat(form.Properties);
        }

        if (additionalParams && (additionalParams.Properties instanceof Array)) {
            properties = properties.concat(additionalParams.Properties);
        }

        const defaultTeams = getDefaultTeams(assignedElement, properties);

        const issue = new Model.Issues.Issue();
        issue.CreatorOID = Session.User.OID;
        issue.EditorOID = Session.User.OID;
        issue.CreationTimestamp = new Date(now.getTime());
        issue.ModificationTimestamp = new Date(now.getTime());
        issue.PriorityOID = Session.Client.Settings.TicketOpenedPriority;
        issue.RemindingTimestamp = remindingTimestamp;
        issue.ID = 0;
        issue.Revision = 0;
        issue.AssignedElementOID = assignedElement.OID;
        issue.IsArchived = false;
        issue.OID = uuid();
        issue.Type = issueType;

        setUserAndTeamResponsibilities(issue, { Teams: defaultTeams });

        const priority = DAL.Properties.GetByOID(issue.PriorityOID);
        if (priority) {
            issue.PriorityRevisionOID = priority.RevisionOID;
        }

        if (Utils.InArray([
            Enums.IssueType.Task,
            Enums.IssueType.Scheduling,
            Enums.IssueType.Form,
            Enums.IssueType.Disturbance,
            Enums.IssueType.Inspection
        ], issue.Type)) {
            switch (issue.Type) {
                case Enums.IssueType.Disturbance:
                    issue.StateOID = Session.Client.Settings.DisturbanceOpened;
                    break;
                case Enums.IssueType.Form:
                    issue.StateOID = Session.Client.Settings.FormOpened;
                    break;
                case Enums.IssueType.Inspection:
                    issue.StateOID = Session.Client.Settings.InspectionOpened || Session.Client.Settings.FormOpened;
                    break;
                default:
                    issue.StateOID = Session.Client.Settings.TicketOpened;
            }

            issue.DeadlineTimestamp = deadline;

            let state = DAL.Properties.GetByOID(issue.StateOID);
            if (state) {
                issue.StateRevisionOID = state.RevisionOID;
            }

            if (Utils.InArray([Enums.IssueType.Form, Enums.IssueType.Inspection], issue.Type)) {
                if (form) {
                    issue.Title = form.Title;

                    const scheduling = DAL.Scheduling.GetByOID(additionalParams.AssignedSchedulingOID);
                    if (!!additionalParams.AssignedSchedulingOID && scheduling) {
                        issue.PriorityOID = scheduling.PriorityOID;
                        issue.Classifications = scheduling.Classifications;

                        setUserAndTeamResponsibilities(issue, scheduling);

                        const property = DAL.Properties.GetByOID(scheduling.StateOID);
                        if (!!scheduling.StateOID && property) {
                            issue.StateOID = property.OID;
                            issue.StateRevisionOID = property.RevisionOID;
                        }

                        if (scheduling.UseFormTitleAndDescription) {
                            issue.Title = form.Title;

                            if (!!form.Description) {
                                issue.Description = form.Description.replace(/<br(\s?\/)?>/ig, '\n')
                            }
                        } else {
                            issue.Title = scheduling.Title;

                            if (!!scheduling.Description) {
                                issue.Description = scheduling.Description.replace(/<br(\s?\/)?>/ig, '\n');
                            }
                        }
                    } else if (!!form.Description) {
                        issue.Description = form.Description.replace(/<br(\s?\/)?>/ig, '\n');
                    }

                    if (!!additionalParams.AssignedSchedulingOID) {
                        issue.AssignedSchedulingOID = additionalParams.AssignedSchedulingOID;
                    }

                    if (form) {
                        if (!!form.InitialStateOID) {
                            const property = DAL.Properties.GetByOID(form.InitialStateOID);
                            if (property) {
                                issue.StateOID = property.OID;
                                issue.StateRevisionOID = property.RevisionOID;
                            }
                        }

                        if ((form.Properties || []).length) {
                            issue.Keywords = $.extend(true, [], form.Properties);
                        }

                        issue.AssignedFormOID = additionalParams.AssignedFormOID;
                        issue.AssignedFormRevisionOID = form.RevisionOID;

                        if (issue.Type === Enums.IssueType.Inspection) {
                            issue.AdditionalData = {
                                SubIssueCounter: 0
                            };

                            issue.CustomID = ((assignedElement.AdditionalData || {}).VisitCounter || 0) + 1;
                        }

                        issue.Resubmissionitems = createFormResubmissionItems(issue.AssignedFormOID);

                        const parameterCounters = getParameterCounters(form);

                        issue.CollectedParameterCount = 0;
                        issue.CollectedRequiredParameterCount = 0;
                        issue.ParameterCount = parameterCounters.Parameters;
                        issue.RequiredParameterCount = parameterCounters.RequiredParameters;
                    }
                }
            } else if (issue.Type === Enums.IssueType.Scheduling) {
                let measure: Model.Elements.Element,
                    scheduling: Model.Scheduling.Scheduling;

                if ((measure = DAL.Elements.GetByOID(additionalParams.AssignedMeasureOID))) {
                    issue.Title = measure.Title;

                    if (!!measure.Description) {
                        issue.Description = measure.Description.replace(/<br(\s?\/)?>/ig, '\n');
                    }

                    issue.AssignedMeasureOID = additionalParams.AssignedMeasureOID;
                    issue.Resubmissionitems = createMeasureResubmissionItems(issue.AssignedMeasureOID);

                    if ((measure.Properties || []).length) {
                        issue.Keywords = $.extend(true, [], measure.Properties);
                    }
                } else if ((scheduling = DAL.Scheduling.GetByOID(additionalParams.AssignedSchedulingOID))) {
                    issue.Title = scheduling.Title;

                    if (!!scheduling.Description) {
                        issue.Description = scheduling.Description;
                    }

                    const property = DAL.Properties.GetByOID(scheduling.StateOID);
                    if (!!scheduling.StateOID && property) {
                        issue.StateOID = property.OID;
                        issue.StateRevisionOID = property.RevisionOID;
                    }

                    issue.AssignedSchedulingOID = scheduling.OID;
                    issue.Resubmissionitems = DAL.Scheduling.CreateResubmissionitemsForCurrentUser(additionalParams.RootElement);
                    issue.Classifications = scheduling.Classifications;
                    issue.PriorityOID = scheduling.PriorityOID;

                    setUserAndTeamResponsibilities(issue, scheduling);
                }
            }

            state = DAL.Properties.GetByOID(issue.StateOID);

            // Bearbeiter als Verantwortlicher hinterlegen
            if ((state && state.ResponsibleUser)) {
                addCurrentUserAsResponsible(issue);
            }
        } else if (issue.Type === Enums.IssueType.Note) {
            issue.IsActive = true;
        }

        if (additionalParams) {
            if (additionalParams.ParentIssue) {
                if (+additionalParams.ParentIssue.ID) {
                    issue.ParentID = additionalParams.ParentIssue.ID;
                } else {
                    issue.ParentOID = additionalParams.ParentIssue.OID;
                }

                if (additionalParams.ParentIssue.Type === Enums.IssueType.Inspection &&
                    !additionalParams.RecorditemOID) {
                    let customID = additionalParams.ParentIssue.CustomID + '.';

                    if (additionalParams.ParentIssue.AdditionalData) {
                        customID += additionalParams.ParentIssue.AdditionalData.SubIssueCounter + 1;
                    } else {
                        customID += 1;
                    }

                    issue.CustomID = customID;
                }

                if (additionalParams.InheritResponsibilities && Utils.HasProperties(additionalParams.ParentIssue.ResponsibilityAssignments)) {
                    issue.MergeResponsibilities(additionalParams.ParentIssue.ResponsibilityAssignments);
                }
            }

            if (additionalParams.IsTemporaryIssue) {
                issue.IsTemporary = true;
            }

            if (additionalParams.ImagePath) {
                const tmpOID = uuid();
                const extension = Utils.GetFileExtension(additionalParams.ImagePath);
                const tmp = {
                    OID: tmpOID,
                    FileURI: additionalParams.ImagePath,
                    Filename: tmpOID + extension,
                    MimeType: extension === '.png' ? 'image/png' : 'image/jpg'
                };

                temporaryIssueFiles[tmpOID] = tmp;

                issue.TemporaryFilesMarkup = issue.TemporaryFilesMarkup || [];
                issue.TemporaryFilesMarkup.push(
                    createTemporaryImageMarkup(tmp, tmp.FileURI)
                );
            }

            if (additionalParams.VoiceMail) {
                const tmpOID = uuid();
                const tmp = {
                    OID: tmpOID,
                    FileURI: additionalParams.VoiceMail.Path,
                    Filename: additionalParams.VoiceMail.Filename,
                    MimeType: additionalParams.VoiceMail.MimeType
                };

                temporaryIssueFiles[tmpOID] = tmp;

                issue.TemporaryFilesMarkup = issue.TemporaryFilesMarkup || [];
                issue.TemporaryFilesMarkup.push(
                    createTemporaryImageMarkup(tmp, tmp.FileURI)
                );
            }

            if ((additionalParams.TemporaryFiles || []).length) {
                temporaryIssueFiles = {};
                issue.TemporaryFilesMarkup = [];
                issue.Files = [];

                additionalParams.TemporaryFiles.forEach(function(file) {
                    issue.TemporaryFilesMarkup.push(
                        createTemporaryImageMarkup($.extend(true, {}, file),
                            Session.IsSmartDeviceApplication ? file.FileURI : file.Content)
                    );

                    temporaryIssueFiles[file.OID] = file;

                    if (!additionalParams.IsTemporaryIssue) {
                        temporaryIssueFiles[file.OID].ModificationType = IssueImageModificationType.MODIFIED;
                    }
                });
            }
        }

        return issue;
    }

    function appendOpenMeasures(form: Model.Elements.Element, issue: Model.Issues.IssueType): Deferred {
        if (!form || !form.AdditionalSettings || !form.AdditionalSettings.CopyOpenMeasures) {
            return $.Deferred().resolve();
        }

        const encodedRevisionIdent = encodeURIComponent(form.RevisionOID);
        const encodedElementIdent = encodeURIComponent(issue.AssignedElementOID);
        const requestUri = `issues/associations?formrevisionoid=${encodedRevisionIdent}&locationoid=${encodedElementIdent}`;

        return Utils.CheckIfDeviceIsOnline()
            .then(() => Utils.Spinner.Show())
            .then(() => Session.RefreshServerSession())
            .then(() => Utils.Http.Get(requestUri, undefined, undefined, undefined, Utils.Http.TIMEOUT_MS_MEDIUM))
            .then((associations: Model.Issues.IAssociations[]) => {
                if (!associations || !associations.length) {
                    return;
                }

                const assignmentDict: Dictionary<{ IssueID: number, Row?: number }[]> = {};

                for (const currentAssociation of associations) {
                    if (!assignmentDict[currentAssociation.CheckpointElementOID]) {
                        assignmentDict[currentAssociation.CheckpointElementOID] = [];
                    }

                    assignmentDict[currentAssociation.CheckpointElementOID].push({
                        IssueID: currentAssociation.IssueID,
                        Row: currentAssociation.RecorditemRow
                    })
                }

                // append associations
                if (!newIssueRevision.AdditionalData) {
                    newIssueRevision.AdditionalData = {};
                }

                newIssueRevision.AdditionalData.Associations = assignmentDict;

                Utils.Spinner.Hide();
            }, function(_response, _state, _error) {
                throw new Model.Errors.HttpError(_error, _response);
            });
    }

    function setUserAndTeamResponsibilities(issue: Model.Issues.Issue, obj: { Teams?: string[], Users?: string[], Contacts?: string[], ContactGroups?: string[] }): void {
        if (Session.LastKnownAPIVersion >= 14) {
            function createRACI(oids: string[], raci: Model.Issues.RACI) {
                const responsibilities: Dictionary<Model.Issues.RACI> = {};

                for (const oid of oids) {
                    responsibilities[oid] = Utils.CloneObject(raci);
                }

                return responsibilities;
            }

            issue.ResponsibilityAssignments = issue.ResponsibilityAssignments || {};
            issue.ResponsibilityAssignments.Users = createRACI(obj.Users || [], { IsResponsible: true });
            issue.ResponsibilityAssignments.Teams = createRACI(obj.Teams || [], { IsResponsible: true });
            issue.ResponsibilityAssignments.Contacts = createRACI(obj.Contacts || [], { IsInformed: true });
            issue.ResponsibilityAssignments.ContactGroups = createRACI(obj.ContactGroups || [], { IsInformed: true });
        } else {
            if (obj.hasOwnProperty('Teams')) {
                issue.Teams = obj.Teams;
            }
            if (obj.hasOwnProperty('Users')) {
                issue.Users = obj.Users;
            }
        }
    }

    function prepareFiles(issue: { Files: any; }, propertyRights: IPropertyRights): any[] {
        let files: { IsReadOnly: boolean; }[];

        if (!issue || !(issue.Files || []).length || !propertyRights) {
            return;
        }

        if (propertyRights.Files) {
            return issue.Files;
        }

        files = $.extend(true, [], issue.Files);

        for (let fCnt = 0, fLen = files.length; fCnt < fLen; fCnt++) {
            files[fCnt].IsReadOnly = true;
        }

        return files;
    }

    function getMaxFilePosition(): number {
        let filePosition = -1;

        (newIssueRevision.Files || []).forEach(function(file) {
            if (+file.Position > filePosition) {
                filePosition = file.Position;
            }
        });

        if (temporaryIssueFiles) {
            for (let ident in temporaryIssueFiles) {
                if (+temporaryIssueFiles[ident].Position > filePosition) {
                    filePosition = temporaryIssueFiles[ident].Position;
                }
            }
        }

        return filePosition;
    }

    function hasParentAndParentIsLocked(): boolean {
        if (!currentIssue.ParentID && !currentIssue.ParentOID) {
            return false;
        }

        const currentlyOpenedInspection = IssueView.GetCurrentIssue();

        if (!currentlyOpenedInspection) {
            return false;
        }

        if (currentlyOpenedInspection.ID === currentIssue.ParentID ||
            currentlyOpenedInspection.OID === currentIssue.ParentOID) {
            return currentlyOpenedInspection.State != null && currentlyOpenedInspection.State.IsLockedState;
        }
    }

    function isPropertyEditable(rightId: Enums.Rights, rights: Dictionary<any>, parentIsLocked: boolean): boolean {
        if (!rights || !rightId) {
            return false;
        }

        if (currentIssue.IsLockedState || parentIsLocked) {
            return false;
        }

        return Utils.UserHasIssueRight(currentIssue, rightId, rights);
    }

    function isStateEditable(rights: Dictionary<any>, parentIsLocked: boolean): boolean {
        if (!rights || parentIsLocked) {
            return false;
        }

        if (currentIssue.Type === Enums.IssueType.Note) {
            return true;
        }

        const allRequiredRecorded = !currentIssue.RequiredParameterCount ||
            (currentIssue.CollectedRequiredParameterCount >= currentIssue.RequiredParameterCount);
        const mayChangeAllIssueStates = Utils.UserHasRight(Session.User.OID, Enums.Rights.ChangeOrSetIncompleteIssueState, true, currentIssue.AssignedElementOID);

        return Utils.UserHasIssueRight(currentIssue, Enums.Rights.IssueProperties_State, rights) &&
            DAL.Properties.IsUserAllowedToChangeState(userRoles, currentIssue.StateOID, allRequiredRecorded, mayChangeAllIssueStates);
    }

    function canWriteComments(): boolean {
        return Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyOnCheckpoints, true, currentIssue.AssignedElementOID);
    }

    function getFakePropertyRights(): IPropertyRights {
        const mayEditIssues = Utils.CanUserModifyIssue(currentIssue);

        return {
            CustomID: mayEditIssues,
            Title: mayEditIssues,
            Description: mayEditIssues,
            Location: mayEditIssues,
            State: mayEditIssues,
            Priority: mayEditIssues,
            Classifications: mayEditIssues,
            Keywords: mayEditIssues,
            Deadline: mayEditIssues,
            Reminding: mayEditIssues,
            Responsibilities: mayEditIssues,
            Contacts: mayEditIssues,
            ContactGroups: mayEditIssues,
            Files: mayEditIssues,
            Activate: mayEditIssues,
            ConvertToDisturbance: mayEditIssues,
            ConvertToTask: mayEditIssues,
            Deactivate: mayEditIssues,
            EstimatedEffort: mayEditIssues,
            Comments: canWriteComments()
        };
    }

    function getVisibleProperties(propertyRights: IPropertyRights): string[] {
        const roles = Utils.GetUserRoles(currentIssue.AssignedElementOID);
        const showAllProperties = (roles || []).some(function(identifier) {
            const role = DAL.Roles.GetByOID(identifier);

            return role && !role.HideDisabledFieldsInIssueViewer;
        });

        const visibleProperties = $.map(propertyRights, function(value, key) {
            if (showAllProperties || value) {
                return key;
            }
        });

        if (canWriteComments() || showAllProperties) {
            visibleProperties.push('Comments');
        }

        return visibleProperties;
    }

    function getPropertyRights(parentIsLocked: boolean): IPropertyRights {
        const rightDictionary = Utils.GetActiveUserRights(Session.User.OID, true, currentIssue.AssignedElementOID);

        return Session.LastKnownAPIVersion < 2 ?
            getFakePropertyRights() : {
                CustomID: isPropertyEditable(Enums.Rights.IssueProperties_CustomId, rightDictionary, parentIsLocked),
                Title: isPropertyEditable(Enums.Rights.IssueProperties_Title, rightDictionary, parentIsLocked),
                Description: isPropertyEditable(Enums.Rights.IssueProperties_Description, rightDictionary, parentIsLocked),
                Location: isPropertyEditable(Enums.Rights.IssueProperties_Location, rightDictionary, parentIsLocked),
                State: isStateEditable(rightDictionary, parentIsLocked),
                Priority: isPropertyEditable(Enums.Rights.IssueProperties_Priority, rightDictionary, parentIsLocked),
                Classifications: isPropertyEditable(Enums.Rights.IssueProperties_Classifications, rightDictionary, parentIsLocked),
                Keywords: isPropertyEditable(Enums.Rights.IssueProperties_Keywords, rightDictionary, parentIsLocked),
                Deadline: isPropertyEditable(Enums.Rights.IssueProperties_Deadline, rightDictionary, parentIsLocked),
                Reminding: isPropertyEditable(Enums.Rights.IssueProperties_Reminding, rightDictionary, parentIsLocked),
                Responsibilities: isPropertyEditable(Enums.Rights.IssueProperties_Responsibilities, rightDictionary, parentIsLocked),
                Contacts: isPropertyEditable(Enums.Rights.IssueProperties_Contacts, rightDictionary, parentIsLocked),
                ContactGroups: isPropertyEditable(Enums.Rights.IssueProperties_ContactGroups, rightDictionary, parentIsLocked),
                Files: isPropertyEditable(Enums.Rights.IssueProperties_Files, rightDictionary, parentIsLocked),
                Activate: isPropertyEditable(Enums.Rights.IssueProperties_ActivateNote, rightDictionary, parentIsLocked),
                ConvertToDisturbance: isPropertyEditable(Enums.Rights.Issues_ConvertTaskToDisturbance, rightDictionary, parentIsLocked),
                ConvertToTask: isPropertyEditable(Enums.Rights.Issues_ConvertNoteToTask, rightDictionary, parentIsLocked),
                Deactivate: isPropertyEditable(Enums.Rights.IssueProperties_DeactivateNote, rightDictionary, parentIsLocked),
                EstimatedEffort: isPropertyEditable(Enums.Rights.IssueProperties_EstimatedEffort, rightDictionary, parentIsLocked),
                Comments: canWriteComments()
            };
    }

    function sortFilesOfNewRevision(issue): void {
        let filePositionDic = {};
        const $sortedFiles = $files.find('li:not(.placeholder)');

        const leng = $sortedFiles.length;
        for (let pos = 0; pos < leng; ++pos) {
            let $sortedImage = $($sortedFiles[pos]);

            if ($sortedImage.attr('data-tmpoid')) {
                filePositionDic[$sortedImage.data('tmpoid')] = pos;
            }

            filePositionDic[$sortedImage.data('filename')] = pos;
        }

        const filesToReposition = (issue.Files || []).concat(Utils.ObjectToArray(temporaryIssueFiles));

        const getPos = (file) => {
            let pos: number;

            if (file.OID) {
                pos = filePositionDic[file.OID];
            }

            if (pos == null && file.Filename) {
                pos = filePositionDic[file.Filename];
            }

            if (pos == null) {
                //Wenn unbekannt dann zum Ende
                pos = Number.POSITIVE_INFINITY;
                console.log('Unbekannte Position:', file);
            }

            return pos;
        };

        (filesToReposition || []).sort((a, b) => getPos(a) > getPos(b) ? 1 : -1);

        for (let fCnt = 0; fCnt < filesToReposition.length; fCnt++) {
            let file = filesToReposition[fCnt];

            if (temporaryIssueFiles[file.OID] != null) {
                file.IsTemporary = true;
            }

            if (file.Position !== fCnt) {
                isModified = true;
            }

            file.Position = fCnt;
        }

        if (Utils.HasProperties(temporaryIssueFiles)) {
            (issue.Files || []).sort((a, b) => a.Position > b.Position ? 1 : -1);

            return;
        }

        this.Files = filesToReposition;
    }

    function changeFilesOrder(): void {
        sortFilesOfNewRevision(newIssueRevision);
        setFooterButtonVisibility();
    }

    function bindEvents() {
        $win.find('.window-header').on('click', onWindowHeaderClick);
        $win.find('.content-header').on('click', onBtnAssignedLocationClick);

        $content.find('.group .header').on('click', onGroupHeaderClick);
        $content.find('.issue-title').on('input', onIssueTitleInput);
        $content.find('.issue-custom-id').on('input', onCustomIDInput);
        $content.find('.issue-description').on('input', onIssueDescriptionInput);
        $content.find('.btn-qrcode').on('click', () => { scanIssueLocation(); });
        $content.find('button[data-property="state"]').on('click', onBtnStateClick);
        $content.find('button[data-property="priority"]').on('click', onBtnPriorityClick);
        $content.find('button[data-property="classifications"]').on('click', onBtnClassificationsClick);
        $content.find('button[data-property="keywords"]').on('click', onBtnKeywordsClick);
        $content.find('button[data-property="deadline-timestamp"]').on('click', onBtnDeadlineTimestampClick);
        $content.find('button[data-property="reminding-timestamp"]').on('click', onBtnRemindingTimestampClick);
        $content.find('button[data-property="responsibilities"]').on('click', onBtnSetResponsibilitiesClick);
        $content.find('button[data-property="contact-groups"]').on('click', onBtnContactGroupsClick);
        $content.find('button[data-property="estimatedEffort"]').on('click', onBtnEstimatedEffortClick);

        $content.on('click', '.file:not(.placeholder).unsaved[data-mimetype^="image/"] .file-content', onTemporaryImageClick);
        $content.on('click', '.file:not(.placeholder).unsaved:not([data-mimetype^="image/"]) .file-content', onFileClick);
        $content.on('click', '.file:not(.placeholder):not(.unsaved) .file-content', onFileClick);
        $content.on('click.stopPropagation', '.file .footer', function(evt: Event) { evt.stopPropagation(); });
        $content.on('click.deleteFile', '.file .delete', onDeleteFile);
        $content.on('click.editFileInformation', '.file .file-header', onEditFileInformationClick);
        $content.on('change', 'input[type="file"]', onFileInput);
        $content.find('img').on('error', Utils.OnImageNotFound);
        $fileScroller.on('dragover', onImagesDragOver);
        $fileScroller.on('drop', onDropImages);

        if ($fileScroller.find('.files') != null &&
            (isNewIssue || Utils.UserHasIssueRight(currentIssue, Enums.Rights.IssueProperties_Files))) {
            ChangeImageOrder.Init($fileScroller.find('.files'), 'files', 'file', changeFilesOrder, 'Files');
        }

        $btnOpenChat.on('click', onBtnOpenChatClick);
        $btnAbort.on('click', onBtnAbortClick);
        $btnSave.on('click', save);

        if (!Session.IsSmartDeviceApplication) {
            document.addEventListener("paste", onPasteFiles, false);
        }

        if ($btnShowFormInformation) {
            $btnShowFormInformation.on('click', onBtnShowFormInformationClick);
        }

        if ($btnShowSchedulingInformation) {
            $btnShowSchedulingInformation.on('click', onBtnShowSchedulingInformationClick);
        }

        if (Session.IsSmartDeviceApplication) {
            $content.find('.file-input').press(onFileButtonPress, onFileButtonClick, 500);
            $content.find('.voice-mail-input').on('click', onBtnCreateVoiceMailClick);
        }
    }

    function prepareNewIssueRevisionCopy(): void {
        const excludedProperties = [
            'Identifier',
            'Room',
            'ResubmissionitemCollection',
            'State',
            'Form',
            'Scheduling',
            'ProgressState',
            'IsLocked',
            'IsTemporary',
            'Images',
            'ShowFiles',
            'IssueCellMarkerClass',
            'NewFiles',
            'PreviousRevisionIdentifiers',
            'ProcessingStatus',
            'Location'
        ];

        newIssueRevision = Utils.CloneObject(currentIssue, excludedProperties);
        newIssueRevision.Location = DAL.Elements.GetByOID(newIssueRevision.AssignedElementOID);
        newIssueRevision.Files = prepareFiles(newIssueRevision, propertyRights);
        newIssueRevision.Comments = newIssueRevision.Comments || [];
    }

    function getFollowState(): Array<Model.Properties.Property | { Title: string, Value: boolean }> {
        if (!newIssueRevision) {
            return null;
        }

        if (newIssueRevision.Type !== Enums.IssueType.Note) {
            const currentState = DAL.Properties.GetByOID(newIssueRevision.StateOID);
            const stateInfo = DAL.Properties.GetStateModificationInfo(newIssueRevision);

            if (stateInfo &&
                stateInfo.StateChangeAllowed &&
                stateInfo.FollowerStates.length &&
                !(currentState && currentState.ClosedState)) {

                return stateInfo.FollowerStates;
            }
        } else {
            const rightsDictionary = Utils.GetActiveUserRights(Session.User.OID, true, newIssueRevision.AssignedElementOID);
            const mayEditIssue = Utils.UserHasIssueRight(newIssueRevision, Enums.Rights.Issues_CreateOrModifyIssues, rightsDictionary);
            const mayEditNotes = Utils.UserHasIssueRight(newIssueRevision, Enums.Rights.Issues_CreateOrModifyNotes, rightsDictionary);

            if (!(mayEditIssue && mayEditNotes)) {
                return null;
            }

            if (!newIssueRevision.IsArchived &&
                Utils.UserHasIssueRight(newIssueRevision, Enums.Rights.IssueProperties_DeactivateNote, rightsDictionary)) {

                return [{
                    Value: false,
                    Title: i18next.t('IssueViewer.Deactivate')
                }];
            } else if (newIssueRevision.IsArchived &&
                Utils.UserHasIssueRight(newIssueRevision, Enums.Rights.IssueProperties_ActivateNote, rightsDictionary)) {

                return [{
                    Value: true,
                    Title: i18next.t('IssueViewer.Activate')
                }];
            }
        }
    }

    function render(issue: Model.Issues.Issue | Model.Issues.RawIssue, disableAbort: boolean = false, zIndex?: number): void {
        let className = 'issue';
        let isNewIssue = false;

        if (!issue) {
            Utils.Spinner.Hide();
            return;
        }

        if (!issue.OID && !issue.ID) {
            isNewIssue = true;
            issue = createNewIssue(issue.Type);
        } else {
            issue.Title = Utils.UnescapeHTMLEntities(issue.Title);
            issue.Description = Utils.UnescapeHTMLEntities(issue.Description);
            issue.CustomID = Utils.UnescapeHTMLEntities(issue.CustomID);
        }

        if (issue.Type === Enums.IssueType.Note) {
            className = 'note';
        } else if (issue.Type === Enums.IssueType.Disturbance) {
            className = 'disturbance';
        }

        _isReadonly = false;

        userRoles = Utils.GetUserRoles(issue.AssignedElementOID);
        currentIssue = DAL.Issues.PrepareIssue(issue, true);

        if (!currentIssue.ID) {
            if (currentIssue.Type === Enums.IssueType.Note) {
                currentIssue.Headline = i18next.t('IssueViewer.NewNote');
                className = 'note';
            } else if (currentIssue.Type === Enums.IssueType.Disturbance) {
                currentIssue.Headline = i18next.t('IssueViewer.NewDisturbance');
                className = 'disturbance';
            } else if (currentIssue.Type === Enums.IssueType.Inspection) {
                currentIssue.Headline = i18next.t('IssueViewer.NewInspection');
            } else {
                currentIssue.Headline = i18next.t('IssueViewer.NewTask');
            }
        }

        const parentIssueIsLocked = hasParentAndParentIsLocked();
        propertyRights = getPropertyRights(parentIssueIsLocked);

        prepareNewIssueRevisionCopy();

        if (currentIssue.Type === Enums.IssueType.Note) {
            propertyRights.State = propertyRights.Activate || propertyRights.Deactivate;
        }

        if (View.CurrentView === Enums.View.FormBatchEdit) {
            _isReadonly = true;
        } else if (!Utils.IsIssueEditableDuringSync(currentIssue)) {
            Utils.ShowAppIsReadOnlyDuringSynchronisationMessage();
            _isReadonly = true;
        } else if (!Utils.CanUserModifyIssue(currentIssue)) {
            _isReadonly = true;
        }

        const form = DAL.Elements.GetByOID(issue.AssignedFormOID);
        const state = DAL.Properties.GetByOID(newIssueRevision.StateOID);

        $win = $(Templates.IssueViewer.Window({
            WindowClassname: className,
            Issue: newIssueRevision,
            Properties: propertyRights,
            VisibleProperties: getVisibleProperties(propertyRights).join(','),
            IsLockedState: state ? state.IsLockedState : false,
            IsReadOnly: _isReadonly,
            AllowOverrideTitleValue: form == null ? (Utils.HasProperties(issue.AdditionalData) ? !!issue.AdditionalData.AllowOverrideTitleValue : true) : !!form.AllowOverrideFormulaValue,
            IsIssueTitleGenerated: Utils.HasProperties(issue.AdditionalData) ? !!issue.AdditionalData.IsIssueTitleGenerated : false,
            IsIssueTitleOverwritten: Utils.HasProperties(issue.AdditionalData) ? !!issue.AdditionalData.IsIssueTitleOverwritten : false
        }));

        if (!isNaN(zIndex)) {
            $win.css('z-index', zIndex);
        }

        if (parentIssueIsLocked) {
            $win.find('.delete').remove();
        }

        $win.find('.file img').on('load', onAfterImageLoaded);

        $docBody.addClass('modal-open');
        $docBody.append($win);

        $viewerOverlay = Utils.Overlay.Generate('olIssueViewer', 1100);
        $content = $win.find('.content');
        $fileScroller = $content.find('.file-scroller');
        $files = $fileScroller.find('.files');
        $footer = $win.find('.footer');
        $btnOpenChat = $content.find('.btn-open-chat');
        $btnAbort = $footer.find('.btn-abort');
        $btnSave = $footer.find('.btn-save');
        $quickSelectState = $footer.find('.quick-select-state');

        $btnShowFormInformation = $footer.find('.btn-show-form-information');
        $btnShowSchedulingInformation = $footer.find('.btn-show-scheduling-information');

        if (Utils.IsSet(quickSelectFollowerStateButtons)) {
            quickSelectFollowerStateButtons.Destroy();
            quickSelectFollowerStateButtons = null;
        }

        bindEvents();
        setFooterButtonVisibility();

        if (propertyRights.State && !_isReadonly && !isNewIssue && !isNewCorrectiveAction) {
            const followStates = getFollowState();

            if (followStates && followStates.length) {
                const buttons = followStates.map((state: Model.Properties.Property) => {
                    return {
                        Color: state.Color,
                        Title: state.ActivationDescription || state.Title,
                        IsLockedState: state.IsLockedState,
                        OID: state.OID,
                        Value: (<any>state).Value   // deaktivieren/aktivieren bei Notizen
                    }
                });

                quickSelectFollowerStateButtons = new Model.ButtonGroup({
                    $Container: $quickSelectState,
                    OverflowAsDropDown: true,
                    ButtonEntities: buttons,
                    ForceFirstItem: true,
                    $DropDownIcon: $('<img src="./img/status.svg" width="25px" height="25px" />'),
                    OnItemClick: onQuickStateSelected,
                    GetRemainingWidth: () => {
                        const $scroller = $footer.children('.scroller');
                        let remainingWidth = $scroller.width();
                        $scroller.children('.item-wrapper:visible').each((_i, item) => {
                            const $elem = $(item);

                            if ($elem.is($quickSelectState.parent())) {
                                //$elem.outerWidth() is wrong if the buttons are wrapped into multiple lines
                                $quickSelectState.children('.btn-group').children().each((_j, btn) => {
                                    remainingWidth -= $(btn).outerWidth(true);
                                });

                                //subtract margin and border
                                remainingWidth -= $elem.outerWidth(true) - $elem.innerWidth();
                            } else {
                                remainingWidth -= $elem.outerWidth(true);
                            }
                        });

                        return remainingWidth;
                    }
                });
            } else {
                $quickSelectState.parent().addClass('hidden');
            }
        } else {
            $quickSelectState.parent().addClass('hidden');
        }

        Utils.Spinner.Hide();

        const $title = $content.find('input[type="text"]:first');

        if ($title.length) {
            $content.find('.issue-title').attr('autofocus', 'autofocus').focus();
        }

        if (disableAbort) {
            $btnAbort.remove();
        }

        window.setTimeout(Resize, 250);
    }

    function loadHistoricalElements(issue: Model.Issues.Issue): Deferred {
        return Session.IsSmartDeviceApplication ?
            IssueView.getRevisionsFromDatabase(issue) :
            IssueView.getRevisionsFromService(issue);
    }

    function initParameterCounter(issue: Model.Issues.Issue, elements: Array<Model.Elements.Element>): void {
        if (!(elements || []).length || !(issue.Resubmissionitems || []).length) {
            return;
        }

        const elementsByRevisionOID: Dictionary<Model.Elements.Element> = {};
        const recorditemsByElementOID: Dictionary<Array<Model.Recorditem>> = {};

        elements.forEach((element) => {
            elementsByRevisionOID[element.RevisionOID] = element;
        });

        (issue.Recorditems || []).forEach((recorditem) => {
            if (!recorditem || recorditem.IsDummy || !recorditem.ElementOID) {
                return;
            }

            if (recorditemsByElementOID[recorditem.ElementOID]) {
                recorditemsByElementOID[recorditem.ElementOID].push(recorditem);
            } else {
                recorditemsByElementOID[recorditem.ElementOID] = [recorditem];
            }
        });

        issue.ParameterCount = 0;
        issue.RequiredParameterCount = 0;
        issue.CollectedParameterCount = 0;
        issue.CollectedRequiredParameterCount = 0;

        issue.Resubmissionitems.forEach((resubitem) => {
            const element = elementsByRevisionOID[resubitem.ElementRevisionOID];

            if (!element || element.Type < 100 || !checkRequirements(element, recorditemsByElementOID)) {
                return;
            }

            issue.ParameterCount++;

            if (element.Required) {
                issue.RequiredParameterCount++;
            }

            const recorditem = <Model.Recorditem>Utils.Where(issue.Recorditems, 'ResubmissionitemOID', '===', resubitem.OID);

            if (recorditem && !recorditem.IsDummy) {
                issue.CollectedParameterCount++;

                if (element.Required) {
                    issue.CollectedRequiredParameterCount++;
                }
            }
        });
    }

    function checkRequirements(element: Model.Elements.Element, recorditemsByElementOID: Dictionary<Model.Recorditem[]>): boolean {
        if (!element) {
            return false;
        }

        if (!Utils.IsSet(element.Requirements) || !Utils.HasProperties(element.Requirements)) {
            return true;
        }

        switch (element.Requirements.Type) {
            case Enums.ElementRequirementsType.All:
                return element.Requirements.Criteria.every((criterion) =>
                    checkCriterion(recorditemsByElementOID[criterion.ElementOID], criterion.ElementOID));
            case Enums.ElementRequirementsType.Any:
                return element.Requirements.Criteria.some((criterion) =>
                    checkCriterion(recorditemsByElementOID[criterion.ElementOID], criterion.ElementOID));
            case Enums.ElementRequirementsType.None:
                return element.Requirements.Criteria.every((criterion) =>
                    !checkCriterion(recorditemsByElementOID[criterion.ElementOID], criterion.ElementOID));
        }

        return false;
    }

    function checkCriterion(recorditems: Array<Model.Recorditem>, categoryOID: string): boolean {
        if (!(recorditems || []).length) {
            return false;
        }

        return recorditems.some(recorditem => recorditem.CategoryOID === categoryOID);
    }

    export function GetCurrentIssue(): Model.Issues.Issue {
        return currentIssue;
    }

    export function GetCloseDeferred(): Deferred {
        return Utils.IsSet(_closeDeferred) ?
            _closeDeferred.promise() :
            null;
    }

    export function IsVisible(): boolean {
        return $win && $win.css('display') !== 'none' || Utils.IsSet(_closeDeferred) && _closeDeferred.state() === 'pending';
    }

    function getFileMarkupForUnsavedNonImageFile(mimeType: string, temporaryIdentifier: string, title: string): string {
        const $unsavedFile = $(Templates.IssueViewer.NewFile({
            FileInfos: {
                MimeType: mimeType,
                OID: temporaryIdentifier,
                Title: title
            }
        }));

        return $('<div></div>').append($unsavedFile).html();
    }

    function getInitialFormState(formIdentifier: string): string {
        if (!formIdentifier) {
            return null;
        }

        const form = DAL.Elements.GetByOID(formIdentifier);

        if (!form) {
            return null;
        }

        return !!form.InitialStateOID ? form.InitialStateOID : Session.Client.Settings.FormOpened;
    }

    function createTemporaryImageMarkup(file, fileContent): string {
        let pictureConfig: MarkSettings;

        if (file.MimeType.startsWith('image/')) {
            pictureConfig = {
                Width: '100%',
                Height: '100%'
            };

            if (!!file.Marks) {
                pictureConfig.File = file;
            }

            if (!Session.IsSmartDeviceApplication) {
                pictureConfig.IsBase64 = true;
                pictureConfig.FileContent = fileContent;
            } else {
                pictureConfig.FilenameContainsPath = true;
                pictureConfig.File = pictureConfig.File || {};
                pictureConfig.File.Filename = fileContent;
            }

            return Templates.IssueViewer.NewFile({
                IsImage: true,
                FileInfos: file,
                Picture: $('<div class="file-image"></div>').append(Utils.GetImageWithMarks(pictureConfig)).html()
            });
        } else {
            return Templates.IssueViewer.NewFile({
                FileInfos: file
            });
        }
    }

    export function CreateIssue(issueType: Enums.IssueType, callback: Function, params?): void {
        // TODO return deferred
        _donotRedirectAfterSaving = false;
        isNewIssue = true;
        isNewFormIssue = false;
        isNewSchedulingIssue = false;
        isNewCorrectiveAction = false;
        additionalParams = params;
        restoreFragment = false;

        render(<Model.Issues.Issue>{
            Type: issueType
        });

        onSave = callback;
    }

    export function CreateMeasure(measureOID: string, callback: Function): void {
        if (!measureOID) {
            return;
        }

        _donotRedirectAfterSaving = false;
        isNewIssue = true;
        isNewFormIssue = false;
        isNewSchedulingIssue = false;
        isNewCorrectiveAction = false;
        restoreFragment = false;
        additionalParams = {
            AssignedMeasureOID: measureOID
        };

        userRoles = [];
        onSave = callback;

        DAL.Sync.PreloadElementCheckpoints(measureOID)
            .then(() => {
                const issue = createNewIssue(Enums.IssueType.Scheduling);
                newIssueRevision = $.extend(true, {}, issue);

                render(issue);
            });
    }

    export function CreateFormIssue(formOID: string, callback: Function): void {
        if (!formOID) {
            return;
        }

        const form = DAL.Elements.GetByOID(formOID);

        _donotRedirectAfterSaving = false;
        isNewIssue = true;
        isNewFormIssue = true;
        isNewSchedulingIssue = false;
        isNewCorrectiveAction = false;
        restoreFragment = false;
        additionalParams = {
            AssignedFormOID: formOID
        };

        userRoles = [];
        onSave = callback;

        DAL.Sync.PreloadElementCheckpoints(formOID)
            .then(() => {
                const issue = createNewIssue(form.IsInspection ? Enums.IssueType.Inspection : Enums.IssueType.Form);
                newIssueRevision = $.extend(true, {}, issue);

                // load associsations (if enabled)
                return appendOpenMeasures(form, issue)
            })
            .always(() => {
                // save newly created and updated issue
                save();
            });
    }

    export function ExecuteScheduling(schedulingOID: string, callback?: Function): Deferred {
        if (!schedulingOID) {
            return $.Deferred().reject();
        }

        const scheduling = DAL.Scheduling.GetByOID(schedulingOID);

        if (!DAL.Scheduling.IsUserAllowedToSeeAllSchedulings() &&
            !scheduling.IsCurrentUserAssigned()) {
            Utils.Spinner.Hide();

            Utils.Message.Show(i18next.t('Misc.SchedulingExecution.NotAvailable.MessageHeader'),
                i18next.t('Misc.SchedulingExecution.NotAvailable.MessageBody'), {
                    Close: true
                });

            return $.Deferred().reject();
        }

        _donotRedirectAfterSaving = true;
        isNewIssue = true;
        isNewCorrectiveAction = false;
        restoreFragment = false;
        userRoles = [];
        onSave = callback;

        return createSchedulingIssues(scheduling);
    }

    function createSchedulingIssues(scheduling: Model.Scheduling.Scheduling): Deferred {
        let lastIssueCreated: Model.Issues.Issue;
        let formCreationChain = $.Deferred().resolve();

        isNewSchedulingIssue = true;

        if (!scheduling) {
            Utils.Spinner.Hide();
            return formCreationChain;
        }

        let parentIssue: Model.Issues.Issue;

        const spinnerLock = Utils.Spinner.Lock('sch-cr');

        if ((scheduling.Forms || []).length) {
            isNewFormIssue = true;

            // Auszuführende Plan-Formulare sortieren, damit ..
            scheduling.Forms.sort((f1: Model.Scheduling.SchedulingMetadataFormElement, f2: Model.Scheduling.SchedulingMetadataFormElement) => {
                if (!f1.IsParentIssueLocation && !f2.IsParentIssueLocation) {
                    // .. eine einheitliche Sortierung Gleichheit beim zuletzt
                    // geöffneten Vorgang in Web und App sicherstellt.
                    return f1.LocationIdentifier.localeCompare(f2.LocationIdentifier, undefined, { sensitivity: 'accent' });
                }

                // .. Übergeordneten Vorgang zuerst erstellt wird.
                return f1.IsParentIssueLocation ? -1 : 1;
            });

            for (let fCnt = 0, fLen = scheduling.Forms.length; fCnt < fLen; fCnt++) {
                const form = scheduling.Forms[fCnt];
                const stateIdentifier = getInitialFormState(form.Identifier);

                if (!Utils.IsUserAbleToSetInitialState(form.LocationIdentifier, stateIdentifier)) {
                    continue;
                }

                if (!Utils.UserHasIssueRight(form.LocationIdentifier, Enums.Rights.Issues_CreateOrModifyForms)) {
                    continue;
                }

                // Mehrfach-Ausführung der Plan-Formulare
                for (let i = 0; i < form.ExecutionCount; i++) {
                    formCreationChain = formCreationChain
                        // benötigte Elemente nachladen
                        .then(() => DAL.Sync.PreloadElementCheckpoints(form.Identifier))
                        .then(() => {
                            additionalParams = {
                                AssignedSchedulingOID: scheduling.OID,
                                AssignedFormOID: form.Identifier,
                                AssignedElementOID: form.LocationIdentifier
                            };

                            if (parentIssue != null) {
                                additionalParams.ParentIssue = parentIssue;
                            }

                            // Formular-Vorgang erstellen
                            const issue = createNewIssue(Enums.IssueType.Form);
                            newIssueRevision = $.extend(true, {}, issue);
                            _donotRedirectAfterSaving = !(fCnt === fLen - 1 && !(scheduling.Elements || []).length);

                            // noch offene Maßnahmen aus vorangehenden Vorgängen an Formular anfügen
                            const formData = DAL.Elements.GetByOID(form.Identifier);
                            return appendOpenMeasures(formData, newIssueRevision)
                                .then(null, () => $.Deferred().resolve())
                                .then(() => {
                                    // save newly created and updated issue
                                    return save();
                                })
                                .then(() => {
                                    if (!parentIssue) {
                                        // zuletzt erstellten Vorgang merken
                                        lastIssueCreated = issue;

                                        if (form.IsParentIssueLocation) {
                                            // Zusammenfassenden Eltern-Vorgang merken
                                            parentIssue = $.extend(true, {}, newIssueRevision);
                                        }
                                    }
                                }, () => $.Deferred().resolve());   // make it positive
                        });
                }
            }
        }

        formCreationChain
            .then(() => {
                // Vorgang für Prüfpunkte am Raum erzeugen
                if (scheduling.Elements && scheduling.Elements.length) {
                    let preloadElements = $.Deferred().resolve();

                    // Benötigte Prüfpunkt-Elemente ermitteln und laden
                    for (const rel of scheduling.Elements) {
                        const locationOID = rel.LocationIdentifier;

                        // Alle Prüfpunkte & -gruppen der OE laden
                        preloadElements = preloadElements.then(() => DAL.Sync.PreloadElementCheckpoints(locationOID));
                    }

                    return preloadElements.then(() => {
                        isNewFormIssue = false;
                        _donotRedirectAfterSaving = false;

                        additionalParams = {
                            AssignedSchedulingOID: scheduling.OID,
                            RootElement: DAL.Scheduling.PrepareSchedulingElements(scheduling.Elements)
                        };

                        if (parentIssue != null) {
                            additionalParams.ParentIssue = parentIssue;
                        }

                        const issue = createNewIssue(Enums.IssueType.Scheduling);
                        newIssueRevision = $.extend(true, {}, issue);

                        return save()
                            .then(() => {
                                lastIssueCreated = issue;
                            }, () => $.Deferred().resolve());   // make it positive
                    })
                } else if (lastIssueCreated && _donotRedirectAfterSaving) {
                    Utils.Router.PushState(`issue/${lastIssueCreated.OID}?type=${lastIssueCreated.Type}`);
                }

                // TODO es sollte immer das lastIssueCreated geöffnet werden!
                // Stattdessen wird aktuell meistens der zuletzt erstellte Vorgang über die
                // onSave() Methode in onAfterSavedToDevice() oder onAfterSavedToServer() geöffnet.
            })
            .always(() => {
                spinnerLock.Unlock();

                Utils.Spinner.Hide();
            });

        return formCreationChain;
    }

    export function CreateCorrectiveAction(
        recorditem: Model.Recorditem,
        element: Model.Elements.Element,
        issue: Model.Issues.Issue,
        action: any,
        isTemporary: boolean,
        callback: Function,
        hideAbortButton: boolean,
        zIndex?: number
    ): Deferred | null {
        if (!recorditem || !element || !action) {
            return $.Deferred().reject();
        }

        _donotRedirectAfterSaving = false;
        isNewIssue = true;
        isNewFormIssue = false;
        isNewSchedulingIssue = false;
        isNewCorrectiveAction = true;
        restoreFragment = false;
        userRoles = [];

        // Prüfpunkt-Titel zusammenstellen
        let checkpointTitleText = '';
        if (element.Parent) {
            if (element.Parent.Parent && element.Parent.Parent.Type === Enums.ElementType.Form) {
                checkpointTitleText += `${element.Parent.Parent.Title} >> `;
            }
            checkpointTitleText += `${element.Parent.Title} >> `;
        }
        checkpointTitleText += element.Title;

        const category = DAL.Properties.GetByOID(recorditem.CategoryOID) || { Title: i18next.t('Misc.Unknown') };

        // Workflow Beschreibung generieren
        let workflowDescription: string;
        if (!Session.Client.Settings.DisableActionAutoDescription) {
            if (!isTemporary) {
                workflowDescription = i18next.t('IssueViewer.WorkflowDescription', {
                    CheckpointTitle: checkpointTitleText,
                    CategoryTitle: category.Title
                });
            } else {
                workflowDescription = i18next.t('IssueViewer.TemporaryWorkflowDescription', {
                    CheckpointTitle: checkpointTitleText
                });
            }
        }

        let deadline: Date;

        if (action.DeadlineOffset &&
            !isNaN(action.DeadlineOffset.Offset) &&
            action.DeadlineOffset.Offset > 0) {
            const multiplier = action.DeadlineOffset.Unit === New.Checkpoints.Workflow.DeadlineOffsetUnit.Hours ?
                60 :
                1440;

            deadline = new Date(new Date().getTime() + action.DeadlineOffset.Offset * multiplier * 60 * 1000);
        }

        additionalParams = {
            IsTemporaryIssue: isTemporary,
            RecorditemOID: recorditem.OID,
            InheritResponsibilities: action.InheritResponsibilities,
            InheritResponsibilitiesFromOU: action.InheritResponsibilitiesFromOU,
            Properties: action.Keywords,
            Deadline: deadline
        };

        if (issue) {
            additionalParams.ParentIssue = issue;
        }

        if ((action.TemporaryFiles || []).length) {
            additionalParams.TemporaryFiles = action.TemporaryFiles;
        }

        if (!!action.LocationOID &&
            Utils.CanUserCreateIssueType(Enums.IssueType.Task, action.LocationOID)) {
            additionalParams.AssignedElementOID = action.LocationOID;
        }

        let form: Model.Elements.Element;
        let preloadElements: Deferred;
        if (action.Type === Enums.CheckpointWorkflowType.Form) {
            form = DAL.Elements.GetByOID(action.FormOID);

            if (!form) {
                Utils.Message.Show(
                    i18next.t('Forms.UnknownForm.MessageHeader'),
                    i18next.t('Forms.UnknownForm.MessageBody'), {
                        Close: true
                    }, null, 1054);

                return null;
            }

            preloadElements = DAL.Sync.PreloadElementCheckpoints(form.OID)
                .then(() => {
                    additionalParams.AssignedFormOID = form.OID;
                    issue = createNewIssue(form.IsInspection ? Enums.IssueType.Inspection : Enums.IssueType.Form);
                });
        } else {
            issue = createNewIssue(Enums.IssueType.Task);
        }

        return (preloadElements || $.Deferred().resolve())
            .then(() => {
                // get AssignedElementOID from recorditem parent
                issue.AssignedElementOID = getLocationOIDFromRecorditem(recorditem) || issue.AssignedElementOID;
                issue.AssignedRecorditemOID = recorditem.OID;
                issue.AssignedRecorditemID = element.LastRecorditem ? element.LastRecorditem.ID : null;
                issue.AssignedActionOID = action.OID;
                issue.Description = workflowDescription;

                _closeDeferred = $.Deferred();

                if (form) {
                    if (!!form.Description) {
                        issue.Description += i18next.t('IssueViewer.FormDescription', {
                            Description: form.Description
                        });
                    }

                    if (!!action.LocationOID &&
                        Utils.CanUserCreateIssueType(Enums.IssueType.Form, action.LocationOID)) {
                        issue.AssignedElementOID = action.LocationOID;
                    }
                } else {
                    if (!!action.Title) {
                        issue.Title = action.Title.length > 100 ?
                            action.Title.substr(0, 97) + '...' :
                            action.Title;
                    }

                    if (!!action.Text) {
                        if (!action.Title) {
                            const singleLineText = action.Text.replace(/<br \/>/ig, '');

                            issue.Title = singleLineText.length > 100 ?
                                singleLineText.substr(0, 97) + '...' :
                                singleLineText;
                        }

                        issue.Description += i18next.t('IssueViewer.DefaultActionDescription', {
                            Text: action.Text
                        });
                    }
                }

                if (action.ResponsibilityAssignments) {
                    issue.MergeResponsibilities(action.ResponsibilityAssignments);
                }

                if (action.Keywords) {
                    issue.Keywords = action.Keywords;
                }

                onSave = callback;

                setTimeout(() => {
                    render(issue, hideAbortButton || false, zIndex);
                }, 10);

                return _closeDeferred.promise();
            });
    }

    function getLocationOIDFromRecorditem(recordItem: Model.Recorditem): string {
        /*
        * liefert den zugehörigen raum zu einem recorditem
        */
        if (!recordItem || !recordItem.Element) {
            return null;
        }

        return (function find(element) {
            if (!element) {
                return null;
            }
            if (element.Type == Enums.ElementType.Form) {
                return null;
            }
            if (element.Type == Enums.ElementType.Root ||
                element.Type == Enums.ElementType.Location) {
                return element.OID;
            }
            return find(element.Parent);
        }(recordItem.Element));
    }

    export function CreateFileNote(params: IAdditionalParams, callback: Function) {
        let issue: Model.Issues.Issue;

        _donotRedirectAfterSaving = false;
        isNewIssue = true;
        isNewFormIssue = false;
        isNewSchedulingIssue = false;
        isNewCorrectiveAction = false;
        additionalParams = params;
        restoreFragment = false;

        issue = createNewIssue(Enums.IssueType.Note);
        newIssueRevision = $.extend(true, {}, issue);

        save();

        onSave = callback;
    }

    export function OpenTemporaryIssue(temporaryIssue, existingTemporaryIssueFiles, callback: Function): Deferred | null {
        let additionalFileCount = Object.keys(existingTemporaryIssueFiles || {}).length;
        let identifierProperty: string;
        let issueFile;
        let rawFile;

        if (IssueViewer.IsVisible()) {
            return null;
        }

        _closeDeferred = $.Deferred();

        _donotRedirectAfterSaving = false;
        isNewIssue = true;
        isNewFormIssue = false;
        isNewSchedulingIssue = false;
        isNewCorrectiveAction = false;
        restoreFragment = false;
        additionalParams = {
            IsTemporaryIssue: true
        };


        if (additionalFileCount) {
            temporaryIssueFiles = existingTemporaryIssueFiles;

            temporaryIssue.TemporaryFilesMarkup = [];

            for (let identifier in temporaryIssueFiles) {
                rawFile = temporaryIssueFiles[identifier];

                identifierProperty = Utils.IsValidGuid(identifier) ?
                    'OID' :
                    'Filename';

                issueFile = Utils.Where(temporaryIssue.Files, identifierProperty, '===', identifier);

                if (!issueFile) {
                    continue;
                }

                issueFile.IsNewTemporaryFile = true;

                rawFile.ModificationType = IssueImageModificationType.MODIFIED;

                temporaryIssue.TemporaryFilesMarkup.push(
                    createTemporaryImageMarkup($.extend(true, {}, issueFile), Session.IsSmartDeviceApplication ? rawFile.FileURI : rawFile.Content));
            }
        }

        temporaryIssue.IsTemporary = true;
        onSave = callback;

        setTimeout(() => {
            render($.extend(true, {}, temporaryIssue));
        }, 10);

        return _closeDeferred;
    }

    export function OpenIssue(identifier: number | string, callback: Function, onCommentSaved: Function = null, onCommentDeleted: Function = null): Deferred {
        if (IsVisible() || !identifier) {
            return $.Deferred().reject();
        }

        Utils.Spinner.Show();

        _closeDeferred = $.Deferred();
        _donotRedirectAfterSaving = false;
        isNewIssue = false;
        additionalParams = {};
        restoreFragment = true;
        isNewFormIssue = false;
        isNewSchedulingIssue = false;
        isNewCorrectiveAction = false;
        _onCommentSaved = onCommentSaved;
        _onCommentDeleted = onCommentDeleted;
        onSave = callback;

        const deferred = Utils.IsValidGuid(<string>identifier) ?
            DAL.Issues.GetByOID(<string>identifier) :
            DAL.Issues.GetByID(<number>identifier);

        deferred.then((issue: Model.Issues.Issue) => {
            if (!issue) {
                return;
            }

            DAL.Issues.PrepareResponsibilityAssignments(issue);

            if (issue.AssignedFormOID || issue.AssignedSchedulingOID) {
                loadHistoricalElements(issue)
                    .then((elements: Model.Elements.Element[]) => {
                        assignedForm = !!issue.AssignedFormRevisionOID ?
                            Utils.FindByPredicate(elements, e => e.RevisionOID === issue.AssignedFormRevisionOID) :
                            null;

                        if (!Utils.IsSet(issue.ParameterCount)) {
                            initParameterCounter(issue, elements);
                        }

                        render(issue);
                    });
            } else {
                render(issue);
            }
        }).fail(function(response, _state, _error) {
            Utils.Spinner.Hide();
            _closeDeferred.resolve();

            if (response != null && response.status === Enums.HttpStatusCode.Not_Found) {
                if (!Utils.Message.IsVisible()) {
                    Utils.Message.Show(
                        i18next.t('IssueViewer.IssueNotFoundError.MessageHeader'),
                        i18next.t('IssueViewer.IssueNotFoundError.MessageBody'),
                        {
                            OK: true
                        }
                    );
                }
                return;
            }

            throw new Error(_error);
        });

        return _closeDeferred;
    }

    export function FinishIssue(issue: Model.Issues.Issue | Model.Issues.RawIssue, callback: Function): void {
        if (!issue) {
            return;
        }

        let state: Model.Properties.Property;

        if (!(issue instanceof Model.Issues.Issue)) {
            issue = new Model.Issues.Issue(issue);
        }

        _donotRedirectAfterSaving = false;

        const userRoles = Utils.GetUserRoles(issue.AssignedElementOID);
        const allRequiredRecorded = !issue.RequiredParameterCount ||
            (issue.CollectedRequiredParameterCount >= issue.RequiredParameterCount);
        const mayChangeAllIssueStates = Utils.UserHasRight(Session.User.OID, Enums.Rights.ChangeOrSetIncompleteIssueState, true, issue.AssignedElementOID);

        if (!!Session.Client.Settings.TicketCompleted &&
            DAL.Properties.IsUserAllowedToChangeState(userRoles, issue.StateOID, allRequiredRecorded, mayChangeAllIssueStates) &&
            DAL.Properties.IsUserAllowedToSetState(userRoles,
                issue,
                issue.StateOID,
                Session.Client.Settings.TicketCompleted)) {
            currentIssue = DAL.Issues.PrepareIssue(issue);

            newIssueRevision = currentIssue.CopyRaw();
            newIssueRevision.StateOID = Session.Client.Settings.TicketCompleted;

            if ((state = DAL.Properties.GetByOID(Session.Client.Settings.TicketCompleted))) {
                newIssueRevision.StateRevisionOID = state.RevisionOID;
                newIssueRevision.IsArchived = state.ClosedState || false;
                newIssueRevision.IsDeleted = state.IsDeletedState || false;
            }

            onSave = callback;

            save();
        } else {
            Utils.Message.Show(i18next.t('IssueViewer.FinishingError.MessageHeader'),
                i18next.t('IssueViewer.FinishingError.MessageBody'), {
                    Close: true
                });
        }
    }

    export function UpdateState(issue: Model.Issues.Issue | Model.Issues.RawIssue, state: Model.Properties.Property, callback: Function): Deferred {
        if (!issue || !state) {
            return $.Deferred().reject().promise();
        }

        // Das _closeDerred kann noch bevor diese Methode versucht das promise zurückzugeben auf null gesetzt werden
        const closeDeferred = $.Deferred();
        _closeDeferred = closeDeferred;

        if (!(issue instanceof Model.Issues.Issue)) {
            issue = new Model.Issues.Issue(issue);
        }

        currentIssue = DAL.Issues.PrepareIssue(issue);

        _donotRedirectAfterSaving = false;
        newIssueRevision = currentIssue.CopyRaw();
        newIssueRevision.StateOID = state.OID;
        newIssueRevision.StateRevisionOID = state.RevisionOID;

        if (state.ClosedState) {
            newIssueRevision.IsArchived = true;
        }

        if (state.IsDeletedState) {
            newIssueRevision.IsDeleted = true;
        }

        if (state.ResponsibleUser) {
            addCurrentUserAsResponsible(newIssueRevision);
        }

        onSave = function(...params: (Model.Issues.Issue | Model.Issues.RawIssue)[]) {
            params.push(issue);
            if (callback && typeof callback === 'function') {
                callback.apply(null, params);
            }
        };

        save();

        return closeDeferred.promise();
    }

    export function AddFile(issue: Model.Issues.RawIssue | Model.Issues.Issue, fileInfo: { Properties: Array<any>, Definition: Dictionary<any> }, callback: Function): Deferred {
        if (!issue || !fileInfo) {
            return $.Deferred().reject();
        }

        if (!(issue instanceof Model.Issues.Issue)) {
            issue = new Model.Issues.Issue(issue);
        }

        currentIssue = DAL.Issues.PrepareIssue(issue);

        const afterFileAdded = function(issue: Model.Issues.Issue) {
            newIssueRevision = issue;
            onSave = callback;

            if (!Session.IsSmartDeviceApplication) {
                return onAfterSavedToServer(issue);
            } else {
                return onAfterSavedToDevice(issue);
            }
        };

        return (<Model.Issues.Issue>currentIssue).AddFiles(
            fileInfo.Properties,
            fileInfo.Definition
        ).then(afterFileAdded);
    }

    export function IncrementSubIssueCount(issue: Model.Issues.Issue): Deferred {
        if (!issue) {
            return $.Deferred().resolve();
        }

        if (!(issue instanceof Model.Issues.Issue)) {
            issue = new Model.Issues.Issue(issue);
        }

        _donotRedirectAfterSaving = false;
        const additionalData = issue.AdditionalData || {
            SubIssueCounter: 0
        };

        additionalData.SubIssueCounter++;

        if (!Session.IsSmartDeviceApplication) {
            return Utils.Http.Put(`issues/${issue.OID}/additionaldata`, additionalData)
                .then(() => additionalData.SubIssueCounter,
                    function(_response, _state, _error) {
                        throw new Model.Errors.HttpError(_error, _response);
                    });
        } else {
            const syncDescription = new Model.Synchronisation.InspectionCounterEntityDescription(
                issue.OID,
                issue,
                Enums.SyncEntityType.SubIssueCounter,
                additionalData,
                null,
                newIssueRevision.ModificationTimestamp);

            return window.Database.SetInStorage(Enums.DatabaseStorage.SyncEntities, syncDescription)
                .then(function() {
                    issue.AdditionalData = additionalData;
                    return DAL.Issues.SaveToDatabase(<any>(issue.CopyRaw ? issue.CopyRaw() : issue), !!issue.CopyRaw);
                })
                .then(() => additionalData.SubIssueCounter);
        }
    }

    export function RepositionMarks(): void {
        const $images = $content.find('.file:not(.placeholder) img');
        let $img, $marks;

        if ($images.length) {
            const iLen = $images.length;
            for (let iCnt = 0; iCnt < iLen; iCnt++) {
                $img = $images.eq(iCnt);
                $marks = $img.siblings('div');

                if ($marks.length) {
                    $marks.css({
                        top: $img.position().top,
                        left: $img.position().left,
                        width: $img.width(),
                        height: $img.height()
                    });

                    $marks.find('> svg')
                        .attr({
                            width: '100%',
                            height: '100%'
                        });
                }
            }
        }
    }

    export function Resize(): void {
        if (quickSelectFollowerStateButtons) {
            quickSelectFollowerStateButtons.Resize();
        }
    }

    export function SaveNewIssueResponsibilities(issue: Model.Issues.Issue | Model.Issues.RawIssue, newResponsibilities: Model.Issues.ResponsibilityAssignments, callback: Function): void {
        if (!issue) {
            return;
        }

        if (!(issue instanceof Model.Issues.Issue)) {
            issue = new Model.Issues.Issue(issue);
        }

        currentIssue = DAL.Issues.PrepareIssue(issue);

        newIssueRevision = currentIssue.CopyRaw();

        onSave = function(...params) {
            params.push(issue);
            if (callback && typeof callback === 'function') {
                callback.apply(null, params);
            }
        };

        updateIssueResponsibilities(newResponsibilities, false);

        save();
    }

    function updateIssueResponsibilities(newResponsibilityAssignments: Model.Issues.ResponsibilityAssignments,
        editedInIssueViewer: boolean = true): void {
        newIssueRevision.ResponsibilityAssignments = newResponsibilityAssignments || {};

        // User übernehmen
        newIssueRevision.ResponsibilityAssignments.Users = newIssueRevision.ResponsibilityAssignments.Users || {};
        const tmpUsers = newIssueRevision.ResponsibilityAssignments.Users;

        for (let userOID in tmpUsers) {
            if (!tmpUsers.hasOwnProperty(userOID)) {
                continue;
            }

            const entity = tmpUsers[userOID];

            // remove empty user from list
            if (!entity.IsResponsible && !entity.IsAccountable && !entity.IsInformed && !entity.IsConsulted) {
                delete tmpUsers[userOID];
            }
        }

        // Teams übernehmen
        newIssueRevision.ResponsibilityAssignments.Teams = newIssueRevision.ResponsibilityAssignments.Teams || {};
        const tmpTeams = newIssueRevision.ResponsibilityAssignments.Teams;

        for (let teamOID in tmpTeams) {
            if (!tmpTeams.hasOwnProperty(teamOID)) {
                continue;
            }

            const entity = tmpTeams[teamOID];

            // remove empty user from list
            if (!entity.IsResponsible && !entity.IsAccountable && !entity.IsInformed && !entity.IsConsulted) {
                delete tmpTeams[teamOID];
            }
        }

        // Kontakte übernehmen
        newIssueRevision.ResponsibilityAssignments.Contacts = newIssueRevision.ResponsibilityAssignments.Contacts || {};
        const tmpContacts = newIssueRevision.ResponsibilityAssignments.Contacts;

        for (let contactOID in tmpContacts) {
            if (!tmpContacts.hasOwnProperty(contactOID)) {
                continue;
            }

            const entity = tmpContacts[contactOID];

            // remove empty user from list
            if (!entity.IsResponsible && !entity.IsAccountable && !entity.IsInformed && !entity.IsConsulted) {
                delete tmpContacts[contactOID];
            }
        }

        // Kontaktgruppen übernehmen
        newIssueRevision.ResponsibilityAssignments.ContactGroups = newIssueRevision.ResponsibilityAssignments.ContactGroups || {};
        const tmpContactGroups = newIssueRevision.ResponsibilityAssignments.ContactGroups;

        for (let contactGroupOID in tmpContactGroups) {
            if (!tmpContactGroups.hasOwnProperty(contactGroupOID)) {
                continue;
            }

            const entity = tmpContactGroups[contactGroupOID];

            // remove empty user from list
            if (!entity.IsResponsible && !entity.IsAccountable && !entity.IsInformed && !entity.IsConsulted) {
                delete tmpContactGroups[contactGroupOID];
            }
        }

        // Wird benötigt um die Zuständigkeiten im Issue report anzuzeigen
        DAL.Issues.PrepareResponsibilityForSync(newIssueRevision);

        if (editedInIssueViewer) {
            setModificationState();
            renderResponsibilities();
        }
    }

    function showChangeCommentWindow(): Deferred {
        const deferred = $.Deferred();

        Utils.InputWindow.Show(
            i18next.t('IssueViewer.ModificationComment.Title'),
            i18next.t('IssueViewer.ModificationComment.InputNote'),
            {
                OK: {
                    Caption: i18next.t('Misc.Okay'),
                    Fn: (text: string) => {
                        const escapedText = Utils.EscapeHTMLEntities(text);

                        if (!!(escapedText).trim()) {
                            newIssueRevision.ChangeComment = escapedText;
                        }

                        deferred.resolve();
                    }
                },
                Abort: {
                    Caption: i18next.t('Misc.Abort'),
                    Fn: deferred.reject
                }
            },
            null,
            'text',
            99999,
            null,
            true,
            255
        );

        return deferred.promise();
    }
}
